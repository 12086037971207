const copyRightStyle = {
  backgroundColor: "#000",
  fontSize: ".75rem",
  color: "#fff",
  border: "2px solid #000",
};

const CopyRightFooter = () => {
  return (
    <div style={copyRightStyle}>
      <p className="gh text-center my-2 col-md-12">
        Copyright 2025 ©{" "}
        <a
          href="https://www.wealth-clinic.com/"
          className="del-underLine"
          style={{
            color: "#ef750f",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          Wealth Clinic{" "}
        </a>
        . All Rights Reserved.
        <a
          className="del-underLine"
          href="https://www.adsrole.com/"
          style={{
            color: "#ef750f",
            marginLeft: ".25rem",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          Powered by AdsRole
        </a>
      </p>
    </div>
  );
};

export default CopyRightFooter;
