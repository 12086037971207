import React, { useState, useEffect, useCallback, useRef } from "react";
import { formatIndianCurrency } from "../../../../helpers/helper";
import { BUDGET_OPTIONS } from "../../../../assets/constants/budget";
import "./PropertyFilters.css";

// Debounce function with useRef to persist across re-renders
const useDebounce = (callback, delay) => {
  const timerRef = useRef(null);

  return useCallback(
    (...args) => {
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay] // callback should not change frequently
  );
};

const BudgetFilter = ({ budget, setBudget }) => {
  const [openPriceList, setOpenPriceList] = useState({
    minPriceList: false,
    maxPriceList: false,
  });

  const [selectBudget, setSelectBudget] = useState(budget);

  useEffect(() => {
    if (budget) {
      setSelectBudget(budget);
    }
  }, [budget]);

  // Use stable debounce function
  const debouncedSetBudget = useDebounce(setBudget, 300);

  const handleInputField = (e) => {
    const { name, value } = e.target;
    const updatedBudget = {
      ...selectBudget,
      [name]: value ? Number(value) : 0,
    };
    setSelectBudget(updatedBudget);
    debouncedSetBudget(updatedBudget);
  };

  const handleSelectBudgetList = (option) => {
    setSelectBudget((prevState) => {
      const updatedBudget = {
        ...prevState,
        minBudget: openPriceList.minPriceList ? option : prevState.minBudget,
        maxBudget: openPriceList.maxPriceList ? option : prevState.maxBudget,
      };
      debouncedSetBudget(updatedBudget);
      return updatedBudget;
    });

    setOpenPriceList({ minPriceList: false, maxPriceList: false });
  };

  const filteredBudgetOptions = BUDGET_OPTIONS.filter((option) => {
    const { minPriceList, maxPriceList } = openPriceList;
    const { minBudget, maxBudget } = selectBudget;

    if (minPriceList && maxBudget) {
      return option < maxBudget;
    }
    if (maxPriceList && minBudget) {
      return option > minBudget;
    }
    return true;
  });

  return (
    <div className="my-4">
      <label className="form-label">
        Budget{" "}
        {selectBudget.maxBudget > 0 &&
          `- ${formatIndianCurrency(selectBudget.maxBudget)}`}
      </label>
      <input
        type="range"
        className="form-range custom-range"
        min={Math.min(...BUDGET_OPTIONS)}
        max={Math.max(...BUDGET_OPTIONS)}
        value={selectBudget.maxBudget}
        onChange={(e) =>
          handleInputField({
            target: { name: "maxBudget", value: e.target.value },
          })
        }
      />
      <div className="d-flex justify-content-between gap-3 align-items-center mt-2">
        <div className="position-relative">
          <input
            autoComplete="off"
            placeholder="Min"
            name="minBudget"
            className="form-control rounded-pill"
            value={
              selectBudget.minBudget
                ? formatIndianCurrency(selectBudget.minBudget)
                : ""
            }
            onChange={handleInputField}
            onClick={() =>
              setOpenPriceList({
                minPriceList: !openPriceList.minPriceList,
                maxPriceList: false,
              })
            }
          />
          {openPriceList.minPriceList && (
            <div className="border mt-2 p-2 budgetPriceList position-absolute top-100 z-10 w-100 bg-white rounded-3 z-10">
              {filteredBudgetOptions.length > 0 ? (
                filteredBudgetOptions.map((option, idx) => (
                  <div
                    key={idx}
                    className="budgetPriceBtn text-black "
                    onClick={() => handleSelectBudgetList(option)}
                  >
                    {formatIndianCurrency(option)}
                  </div>
                ))
              ) : (
                <div className="p-1 text-muted">No options available</div>
              )}
            </div>
          )}
        </div>
        <div>To</div>
        <div className="position-relative">
          <input
            autoComplete="off"
            placeholder="Max"
            name="maxBudget"
            className="form-control rounded-pill"
            value={
              selectBudget.maxBudget
                ? formatIndianCurrency(selectBudget.maxBudget)
                : ""
            }
            onChange={handleInputField}
            onClick={() =>
              setOpenPriceList({
                minPriceList: false,
                maxPriceList: !openPriceList.maxPriceList,
              })
            }
          />
          {openPriceList.maxPriceList && (
            <div className="border mt-2 p-2 budgetPriceList position-absolute top-100 z-10 w-100 bg-white rounded-3 z-10">
              {filteredBudgetOptions.length > 0 ? (
                filteredBudgetOptions.map((option, idx) => (
                  <div
                    key={idx}
                    className="p-1 cursor-pointer budgetPriceBtn text-black"
                    onClick={() => handleSelectBudgetList(option)}
                  >
                    {formatIndianCurrency(option)}
                  </div>
                ))
              ) : (
                <div className="p-1 text-muted">No options available</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BudgetFilter;
