import { useCallback, useState } from "react";

import { ButtonDarkBlue } from "./../../../../../globalComponents/molecules/ButtonDarkBlue";
import InputCustom from "../../../../../globalComponents/molecules/InputCustom";
import ThankYouMessage from "../../../../../globalComponents/ThankYouMessage";
import PopUpCard from "../../../../../globalComponents/PopUpCard";

import { submissionOnZoho } from "./../../../../../../assets/constants/zoho";
import { PROCESS_MESSAGE } from "../../../../../../assets/constants/messages";
import "./downloadPopUpForm.css";

const DownloadPopUpForm = ({
  isOpen,
  onClose,
  formName,
  handleDownloadPdfs,
}) => {
  const INITIAL_FORM_FIELDS = {
    email: "",
    formName: formName || "",
    phone: 1111111111,
  };

  const [formValue, setFormValues] = useState(INITIAL_FORM_FIELDS);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { email } = formValue;

      if (!isValidEmail(email)) {
        setError("Please enter a valid email address.");
        return;
      }

      setError("");
      setIsBtnDisabled(true);

      try {
        const response = await submissionOnZoho(formValue);

        if (response) {
          setIsBtnDisabled(false);
          setIsSuccessfullySubmitted(true);
          setFormValues({ email: "", formName: "" });
          await handleDownloadPdfs();
        } else {
          alert("Failed to submit the form.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("Something went wrong.");
        setIsBtnDisabled(false);
      }
    },
    [formValue, handleDownloadPdfs]
  );

  const handleThankYouComplete = () => setIsSuccessfullySubmitted(false);

  return (
    <>
      <PopUpCard
        removeBtn={false}
        isOpen={isOpen}
        onClose={onClose}
        className="downloadPopUpFormContainer"
        popUpInnerBoxClass="innerDownloadPopUpFormBox"
      >
        <div className="downloadFormContainer">
          <div className="downloadFormBox">
            <div
              className="downloadFormHead"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className="py-2 h-100">
                <img
                  alt="WC%20Logo-black"
                  src="https://www.wealth-clinic.com/frontend/images/WC%20Logo-black.png"
                  className="img-fluid"
                />
              </div>
              <div className="py-1">
                <button className="closeBtn" onClick={onClose}>
                  X
                </button>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <InputCustom
                placeholder="Enter Your Email"
                name="email"
                value={formValue.email}
                onChange={handleChange}
                className="rounded-1"
              />
              {error && <p className="alertMsg">{error}</p>}

              <ButtonDarkBlue
                className="rounded-1 position-hc w-50 bg-black"
                name={`${
                  isBtnDisabled
                    ? PROCESS_MESSAGE.FILE_DOWNLOAD_IN_PROGRESS
                    : "Request Download"
                }`}
                disabled={isBtnDisabled}
              />
            </form>
          </div>
          {isSuccessfullySubmitted && (
            <ThankYouMessage onComplete={handleThankYouComplete} />
          )}
        </div>
      </PopUpCard>
    </>
  );
};

export default DownloadPopUpForm;
