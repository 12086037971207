import { Link } from "react-router-dom";
import useApiFetcher from "../../../hooks/useApiFetcher";

import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
import { EMPTY_OBJECT } from "../../../assets/constants";
import "./stickyIcons.css";

const StickySocialIcons = () => {
  const url = getFullUrl(API_URL.SOCIAL_MEDIA_ICONS);

  const [socialIcons, error, isLoading] = useApiFetcher(url);
  if (error) <div>{error.message}</div>;
  if (isLoading) <div>Loading....</div>;

  const {
    // Facebook_URL = "",
    // Instagram_URL = "",
    Twitter_URL = "",
    // Linkdin_URL = "",
    YouTube_URL = "https://www.youtube.com/watch?v=ndj7ANrFGzo&ab_channel=WealthClinic",
    WhatsApp_URL = "https://api.whatsapp.com/send?phone=+919711611652&text=Hi%20I%20am%20interested%20in%20Wealth%20Clinic%E2%80%99s%20Property.%20Please%20contact%20me%20to%20proceed%20with%20this%20lucrative%20realty%20option..%22",
  } = socialIcons?.attributes || EMPTY_OBJECT;

  return (
    // <div className="sticky-icon">
    //   <Link to={WhatsApp_URL} className="whatsapp" target="_blank">
    //     <i className="fa-brands fa-whatsapp rotate-logo"></i>{" "}
    //     <span className="textSocial">whatsapp</span>
    //   </Link>
    //   {/* <Link
    //     to="https://meet.google.com/?authuser=0"
    //     className="video"
    //     target="_blank"
    //   >
    //     <i className="fa-solid fa-video rotate-logo"></i>{" "}
    //     <span className="textSocial">video</span>
    //   </Link> */}
    //   <Link
    //     to="mailto:customercare@wealth-clinic.com"
    //     className="envelope"
    //     target="_blank"
    //   >
    //     <i className="fa-regular fa-envelope rotate-logo"></i>{" "}
    //     <span className="textSocial">Email</span>
    //   </Link>
    //   <Link to={YouTube_URL} className="Youtube" target="_blank">
    //     <i className="fab fa-youtube rotate-logo"></i>{" "}
    //     <span className="textSocial">Youtube</span>
    //   </Link>
    //   <Link to={Twitter_URL} className="Twitter" target="_blank">
    //     {/* <i className="fab fa-twitter rotate-logo"> </i>{" "} */}
    //     <i className="bi bi-twitter-x"></i>
    //     <span className="textSocial">Twitter</span>
    //   </Link>
    // </div>
    <div className="sticky-icon">
      <Link
        to={WhatsApp_URL}
        className="whatsapp"
        target="_blank"
        aria-label="WhatsApp"
      >
        <i className="fa-brands fa-whatsapp rotate-logo" aria-hidden="true"></i>
        <span className="textSocial">WhatsApp</span>
      </Link>

      <Link
        to="mailto:customercare@wealth-clinic.com"
        className="envelope"
        target="_blank"
        aria-label="Email"
      >
        <i
          className="fa-regular fa-envelope rotate-logo"
          aria-hidden="true"
        ></i>
        <span className="textSocial">Email</span>
      </Link>

      <Link
        to={YouTube_URL}
        className="Youtube"
        target="_blank"
        aria-label="YouTube"
      >
        <i className="fab fa-youtube rotate-logo" aria-hidden="true"></i>
        <span className="textSocial">YouTube</span>
      </Link>

      <Link
        to={Twitter_URL}
        className="Twitter"
        target="_blank"
        aria-label="Twitter"
      >
        <i className="bi bi-twitter-x" aria-hidden="true"></i>
        <span className="textSocial">Twitter</span>
      </Link>
    </div>
  );
};

export default StickySocialIcons;
