import { getHtmlTag } from "./helper";
import { EMPTY_OBJECT } from "../../../assets/constants/index";

const CkEditorContentDisplay = ({ content }) => {
  if (!content) return null;

  const { type, format, children, level } = content || EMPTY_OBJECT;
  let Tag = getHtmlTag(type);

  // Function to render children properly
  const renderChildren = (nodes) => {
    return nodes.map((node, index) => {
      if (node.type === "text") {
        return node.text;
      }
      if (node.type === "link") {
        return (
          <a
            key={index}
            href={node.url}
            // target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-none fw-bold"
          >
            {node.children.map((child) => child.text).join("")}
          </a>
        );
      }
      return null;
    });
  };

  // Render Paragraph
  if (type === "paragraph") {
    return <Tag>{renderChildren(children)}</Tag>;
  }

  // Render Headings
  if (type === "heading") {
    const HeadingTag = `h${level}`;
    return <HeadingTag>{renderChildren(children)}</HeadingTag>;
  }

  // Render Lists
  if (type === "list") {
    const ListTag = getHtmlTag(format);
    return (
      <ListTag>
        {children.map((item, index) => (
          <li key={index}>{renderChildren(item.children)}</li>
        ))}
      </ListTag>
    );
  }

  return null;
};

export default CkEditorContentDisplay;

// export const typeFormat = [
//   {
//     types: "list",
//     format: "ordered",
//   },
//   {
//     types: "list",
//     format: "unordered",
//   },
//   { type: "list-item" },
// ];

// "bold": true,  "italic": true,  "underline": true,  "strikethrough": true  if these things are true then we will apply conditional  style

// const CkEditorContentDisplay2 = ({ data }) => {
//   data?.map((item) => {
//     if (item?.type === "paragraph") return <p>{item?.children[0]?.text}</p>;

//     if (item?.type === "heading") return <h3>{item?.children[0]?.text}</h3>;

//     return null;
//   });
// };

// switch (type) {
//   case "paragraph":
//     return <p>{children?.[0]?.text}</p>;
//   case "heading":
//     return React.createElement(tagType("h", level), {}, children?.[0]?.text);
//   case "list":
//     return React.createElement(getHtmlTag(format), {}, <li>{children?.[0]?.text}</li>);
//   default:
//     return null;
// }

export const CkEditorContentDisplayNew = ({ text: htmlData }) => {
  return (
    <div className="newCkEdiotr">
      <div dangerouslySetInnerHTML={{ __html: htmlData }} />
    </div>
  );
};
