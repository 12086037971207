import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "./WhatsAppChat.css";
// import useApiFetcher from "../../../hooks/useApiFetcher";
// import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
// import { EMPTY_OBJECT } from "../../../assets/constants";
import useApiFetcher from "../../hooks/useApiFetcher";
import { API_URL, getFullUrl } from "../../assets/constants/apiUrls";
import { EMPTY_OBJECT } from "../../assets/constants";

const WhatsAppChat = () => {
  const url = getFullUrl(API_URL.SOCIAL_MEDIA_ICONS);
  const [socialIcons, error, isLoading] = useApiFetcher(url);

  if (error) return <div>{error.message}</div>;
  if (isLoading) return <div>Loading...</div>;

  const { WhatsApp_URL = "https://wa.me/918423838136?text=Hello there!" } =
    socialIcons?.attributes || EMPTY_OBJECT;

  return (
    <div
      className="position-fixed d-md-none z-3"
      style={{ bottom: "40px", left: "17px" }}
    >
      <a href={WhatsApp_URL} target="_blank" rel="noopener noreferrer">
        <button
          className=" rounded-circle  p-2 whatsappchatanimation"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <FaWhatsapp size={30} style={{ color: "#f37535" }} />
        </button>
      </a>
    </div>
  );
};

export default WhatsAppChat;
