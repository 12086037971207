import React, { useState } from "react";
import "./SubscribeSection.css";
import Esclipse from "../../../assets/image4.png";
import Esclipse1 from "../../../assets/Ellipse14.png";
import Esclipse2 from "../../../assets/Ellipse15.png";
import Esclipse3 from "../../../assets/Image3.png";
import { submissionOnZoho } from "../../../assets/constants/zoho";
import useDeviceSize from "../../../hooks/useDeviceSize";

const SubscribeSection = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [emailSubmitting, setEmailSubmitting] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const isMobile = useDeviceSize();
  const validateForm = () => {
    const validationErrors = {};
    if (!email.trim()) {
      validationErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      validationErrors.email = "Invalid email format.";
    }
    return validationErrors;
  };

  const handleSubscribe = async (event) => {
    event.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        setEmailSubmitting(true);
        await submissionOnZoho({ email });
        setMessage("Thank You For Subscribing");
        setSubscribed(true);
        setEmail("");
        setErrors({});
        setEmailSubmitting(false);
        setTimeout(() => {
          setSubscribed(false);
          setMessage("");
        }, 3000);
      } catch (error) {
        setMessage("Subscription failed. Please try again.");
      }
    } else {
      setErrors(validationErrors);
      setEmailSubmitting(false);
    }
  };

  return (
    <section className="subscribe-section p-5">
      <div className="container">
        <div className="row align-items-center">
          {subscribed ? (
            <div className="col-md-6 text-center">
              {/* Show Thank You message */}
              <div
                className="thank-you-popup"
                style={{
                  padding: "20px",
                  background: "#ef750f",
                  color: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                }}
              >
                <h3>Thank You For Subscribing!</h3>
                <p>We appreciate your subscription.</p>
              </div>
            </div>
          ) : (
            <div className="col-md-6 col-12 d-flex flex-column">
              <h2 className="fw-bold mb-3 text-center">
                Subscribe to Wealth Clinic
              </h2>
              <p className="mb-4 text-center">
                The latest news and updates from Wealth Clinic sent to your
                inbox.
              </p>
              <form style={{ width: "100%" }} onSubmit={handleSubscribe}>
                <div className="d-flex bg-white rounded-pill ">
                  <input
                    type="email"
                    className="form-control rounded-start-pill"
                    placeholder="Your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      height: "50px",
                      // padding: "10px",
                      // width: "100%",
                      border: "none",
                    }}
                    required
                  />
                  <button
                    type="submit"
                    className="btn-orange rounded-pill"
                    style={{
                      padding: "10px 36px",
                      border: "none",
                    }}
                  >
                    {emailSubmitting ? "Subscribing..." : "Subscribe"}
                  </button>
                </div>
              </form>
              {errors.email && (
                <p className="text-danger mt-2">{errors.email}</p>
              )}
              {message && (
                <p className=" mt-2" style={{ color: "#ef750f" }}>
                  {message}
                </p>
              )}
            </div>
          )}

          <div className="col-md-6 d-flex justify-content-end">
            <div className="image-grid">
              <div className="row">
                <div className="col-6 mb-3">
                  <img
                    src={Esclipse}
                    alt="Building 1"
                    className="img-fluid "
                    style={{ marginTop: "22px" }}
                  />
                </div>
                <div className="col-6 mb-3">
                  <img
                    src={Esclipse1}
                    alt="Interior"
                    className="img-fluid mt-3"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <img
                    src={Esclipse2}
                    alt="Building 2"
                    style={{ width: isMobile ? "115px" : "193px" }}
                  />
                </div>
                <div className="col-6">
                  <img src={Esclipse3} alt="Building 3" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubscribeSection;
