import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./MultiSelect.css"; // Import the CSS file

const MultiSelect = ({ label, options, selectedValues, setSelectedValues }) => {
  // Local state to manage selected items
  const [selectedItems, setSelectedItems] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  // Synchronize local state with selectedValues prop
  useEffect(() => {
    setSelectedItems(selectedValues || []);
  }, [selectedValues]);

  // Handle selection changes
  const handleSelectionChange = (item) => {
    setSelectedItems((prev) => {
      let updatedItems;
      if (prev.includes(item)) {
        updatedItems = prev.filter((val) => val !== item);
      } else {
        updatedItems = [...prev, item];
      }
      setSelectedValues(updatedItems);
      return updatedItems;
    });
  };

  return (
    <div className="mb-3 ">
      <label className="form-label">{label}</label>

      {/* Dropdown Toggle Button */}
      <div
        className="border p-2 rounded-5 px-3 d-flex justify-content-between align-items-center"
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        style={{ cursor: "pointer", background: "#fff" }}
      >
        <span>
          {selectedItems.length > 0
            ? selectedItems.join(", ")
            : `Select ${label}`}
        </span>
        <span>{isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}</span>
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div
          className="border p-3 rounded-4  bg-white w-100 dropdown-scroll mt-1"
          style={{ zIndex: 1000 }}
        >
          {options.map((item, index) => (
            <div key={index} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`${label}-${index}`}
                checked={selectedItems.includes(item)}
                onChange={() => handleSelectionChange(item)}
              />
              <label className="form-check-label" htmlFor={`${label}-${index}`}>
                {item}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;

// import React, { useState, useEffect } from "react";

// const MultiSelect = ({ label, options, selectedValues, setSelectedValues }) => {
//   // Local state to manage selected items
//   const [selectedItems, setSelectedItems] = useState([]);

//   // Synchronize local state with selectedValues prop
//   useEffect(() => {
//     setSelectedItems(selectedValues || []);
//   }, [selectedValues]);

//   // Handle selection changes
//   const handleSelectionChange = (item) => {
//     setSelectedItems((prev) => {
//       let updatedItems;
//       if (prev.includes(item)) {
//         updatedItems = prev.filter((val) => val !== item);
//       } else {
//         updatedItems = [...prev, item];
//       }
//       // Update parent state
//       setSelectedValues(updatedItems);
//       return updatedItems;
//     });
//   };

//   return (
//     <div className="mb-3">
//       <label className="form-label">{label}</label>
//       <div
//         className="border p-2 rounded"
//         style={{ maxHeight: "250px", overflowY: "auto" }}
//       >
//         {options.map((item, index) => (
//           <div key={index} className="form-check">
//             <input
//               type="checkbox"
//               className="form-check-input"
//               id={`${label}-${index}`}
//               checked={selectedItems.includes(item)}
//               onChange={() => handleSelectionChange(item)}
//             />
//             <label className="form-check-label" htmlFor={`${label}-${index}`}>
//               {item}
//             </label>
//           </div>
//         ))}
//       </div>

//       {/* Selected Items Display */}
//       {/* {selectedItems.length > 0 && (
//         <div className="mt-3">
//           <h5>Selected {label}:</h5>
//           <div className="d-flex text-white flex-wrap gap-2 ">
//             {selectedItems.map((item, index) => (
//               <div
//                 key={index}
//                 className="badge rounded-pill p-2 d-flex gap-2"
//                 style={{ cursor: "pointer", background: "#EF750F" }}
//                 onClick={() => handleSelectionChange(item)}
//               >
//                 <span>{item}</span>
//                 <span>X</span>
//               </div>
//             ))}
//           </div>
//         </div>
//       )} */}
//     </div>
//   );
// };

// export default MultiSelect;
