import { Link } from "react-router-dom";

import CustomLink from "../../CustomLink";
import Heading from "../../globalComponents/molecules/Heading";

import useDeviceSize from "../../../hooks/useDeviceSize";
import searchBuilding from "../../../assets/searchBuilding.png";
import buildingRent from "../../../assets/buildingRent.png";

import { generateBlogDetailsURL } from "../../../helpers/getRedirectUrl";
import URL from "../../../assets/constants/url";
// import { BLOGS_SECTION_CARD } from "../../../assets/constants/home";
import "./advantageSection.css";

const AdvantageSection = () => {
  // const isMobile = useDeviceSize();
  // const [imageLoaded, setImageLoaded] = useState(false);

  // const handleImageLoad = () => {
  //   // Use startTransition to make this state update non-urgent
  //   startTransition(() => {
  //     setImageLoaded(true);
  //   });
  // };
  return (
    <section id="About" className="companyWork my-5 mt-0">
      <div className="text-content container">
        <div>
          {/* <h1>
            Stay updated with the
            <span className="text-danger"> Real estate industry</span>
          </h1> */}
          <Heading
            text={"Blogs"}
            fontWeight={500}
            color={"white"}
            className="ku text-center "
          />
          <p className="text-lg-center ku mt-2" style={{ fontSize: "1.25rem" }}>
            Stay updated with the Real estate industry
          </p>
        </div>
        {/* <CustomLink to={URL.BLOGS}>
          <button className="projectBtn btn my-3 border rounded-4 text-white">
            Blogs <i className="bi bi-arrow-right-short"></i>
          </button>
        </CustomLink> */}
      </div>
      <div className="container">
        <div className="row center-item">
          <div className="  col-6 col-lg-3 my-md-3 col-md-6 center-item p-2">
            <div className="project-Card-container">
              <img
                alt="realstate"
                src={require("../../../assets/realState.jpg")}
                loading="lazy"
                className=" "
              />
              <h6 className="mt-1 fw-light mt-4 text-center">
                New Agra will be Built on Both Sides of the Expressway: Real
                Estate Also Boom
              </h6>
              <p className="fs-sm">
                In Noida, the Yamuna Development Authority has prepared the
                blueprint for the new Agra that will be built with the
                coordination
              </p>
              <button className=" w-100 mt-auto d-flex bg-transparent  border rounded-4  mx-auto">
                <CustomLink
                  to={generateBlogDetailsURL(
                    "new-agra-will-be-built-on-both-sides-of-the-expressway-real-estate-also-boom"
                  )}
                  state={{ blogId: 10 }}
                  className="del-underLine  w-100   py-1 "
                >
                  Explore
                </CustomLink>
              </button>
            </div>
          </div>
          <div className="  col-6 col-lg-3 my-md-3 col-md-6 center-item col-sm-6 col-xs-6 p-2">
            <div className="project-Card-container">
              <img
                alt="flightTicket"
                src={require("../../../assets/flightTicket.jpg")}
                loading="lazy"
                className=" "
              />
              <h6 className="mt-1 fw-light fs-sm  mt-4 text-center">
                A Great News for Investors! Noida Airport Flight's Tickets to Go
                on Sale Next Month
              </h6>
              <p className="fs-sm">
                A Good news is coming regarding Asia's largest airport being
                built in Greater Noida. Ticket booking is likely to start from
                Noida's Jewar Airport .
              </p>
              <button className=" w-100 mt-auto d-flex bg-transparent border rounded-4  mx-auto">
                <CustomLink
                  to={generateBlogDetailsURL(
                    "a-great-news-for-investors-noida-airport-flight-s-tickets-to-go-on-sale-next-month"
                  )}
                  state={{ blogId: 10 }}
                  className="del-underLine  w-100   py-1"
                >
                  Explore
                </CustomLink>
              </button>
            </div>
          </div>
          <div className="  col-6 col-lg-3 my-md-3 col-md-6 center-item col-sm-6 col-xs-6 p-2">
            <div className="project-Card-container">
              <img
                alt="penthouse"
                src={require("../../../assets/penthouse.jpg")}
                loading="lazy"
                className=" "
              />
              <h6 className="mt-1 fw-light fs-sm  mt-4 text-center text-center">
                Luxury Hits a High: Penthouse in DLF Camellias Sold for Rs Drag
                190 Crore
              </h6>
              <p className="fs-sm">
                A luxurious penthouse at DLF Camellias, situated in the affluent
                Golf Course Road neighborhood of Gurgaon, has sold for Rs 190
                crore in a record real estate transaction.
              </p>
              <button className=" w-100 mt-auto d-flex bg-transparent  border rounded-4  mx-auto">
                <CustomLink
                  to={generateBlogDetailsURL(
                    "luxury-hits-a-high-penthouse-in-dlf-camellias-sold-for-rs-drag-190-crore"
                  )}
                  state={{ blogId: 10 }}
                  className="del-underLine  w-100   py-1 "
                >
                  Explore
                </CustomLink>
              </button>
            </div>
          </div>
          <div className="col-6 col-lg-3 my-md-3 col-md-6 center-item col-sm-6 col-xs-6 p-2">
            <div className="project-Card-container">
              <img
                alt="investing_real_state"
                src={require("../../../assets/investing_real_state.jpg")}
                loading="lazy"
                className=" "
              />
              <h6 className="mt-1 fw-light fs-sm  mt-4 text-center">
                Investing in Real Estate: Is It a Good Time to Buy Property in
                noida
              </h6>
              <p className="fs-sm">
                Investing in a property is one of the most important decisions
                anyone makes. For taking this decision of Real Estate Investment
                in Noida is important to understand.
              </p>
              <button className=" w-100 mt-auto d-flex bg-transparent  border rounded-4  mx-auto">
                <CustomLink
                  to={generateBlogDetailsURL(
                    "investing-in-real-estate-is-it-a-good-time-to-buy-property-in-noida"
                  )}
                  state={{ blogId: 14 }}
                  className="del-underLine  w-100   py-1 "
                >
                  Explore
                </CustomLink>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvantageSection;

// export function BlogsSectionCard({ blogs }) {
//   return (
//     <>
//       {blogs.map((blog) => (
//         <div key={blog.id}>
//           <img src={blog.image} alt={blog.title} loading="lazy" />
//           <h5 className="mt-1 fw-bold">
//             {blog.date} | {blog.category}
//           </h5>
//           <p>{blog.description}</p>
//           <CustomLink
//             to={generateBlogDetailsURL(blog.title)}
//             state={{ blogId: blog.id }}
//             className="del-underLine"
//           >
//             <button className="btn btn-outline-dark border rounded-4 w-75 mx-auto mb-3">
//               Explore
//             </button>
//           </CustomLink>
//         </div>
//       ))}
//     </>
//   );
// }

export const HomePageBlogCardMob = () => {
  <section id="About" className="companyWork my-5">
    <div className="text-content">
      <div>
        {/* <h1>
            Stay updated with the
            <span className="text-danger"> Real estate industry</span>
          </h1> */}
        <Heading
          text={"Blogs"}
          fontWeight={700}
          color={"var(--global-heading-color)"}
          className="ku text-center"
        />
        <p className="text-lg-center ku " style={{ fontSize: "1.2rem" }}>
          Stay updated with the Real estate industry
        </p>
      </div>
      <Link to={URL.BLOGS}>
        <button className="projectBtn btn my-3 border rounded-4 text-white">
          Blogs <i className="bi bi-arrow-right-short"></i>
        </button>
      </Link>
    </div>

    <div className="project-Card-container">
      <div>
        <img
          alt="realState"
          src={require("../../../assets/realState.png")}
          loading="lazy"
        />
        <h5 className="mt-1 fw-light">JAN 31, 2024 | DELHI NCR</h5>
        <p>
          From Connectivity to Prosperity: Unveiling Noida's Infrastructure
          Marvel From Connectivity to Prosperity: Unveiling Noida's
          Infrastructure Marvel
        </p>

        <button className="btn btn-outline-dark border rounded-4 w-75 mx-auto mb-3">
          Explore
        </button>
      </div>

      <div>
        <img src={searchBuilding} alt="searchBuilding" loading="lazy" />
        <h5 className="mt-1 fw-bold">FEB 2, 2024 | INVESTMENT</h5>
        <p>
          Smart Investments: Why Properties in M3M The Line In Noida is the
          Commercial Hub Smart Investments: Why Properties in M3M The Line In
          Noida is the Commercial Hub
        </p>

        <button className="btn btn-outline-dark border rounded-4 w-75 mx-auto mb-3">
          Explore
        </button>
      </div>
      <div>
        <img src={buildingRent} alt="buildingRent" loading="lazy" />
        <h5 className="mt-1 fw-bold">FEB 6, 2024 | REAL ESTATE NEWS</h5>
        <p>
          Investing in Tomorrow: Commercial Real Estate Trends in Noida's Orion
          132 Investing in Tomorrow: Commercial Real Estate Trends in Noida's
          Orion 132
        </p>
        <button className="btn btn-outline-dark border rounded-4 w-75 mx-auto mb-3">
          Explore
        </button>
      </div>
      <div>
        <img
          src={require("../../../assets/building.png")}
          alt="building"
          loading="lazy"
        />
        <h5 className="mt-1 fw-bold">FEB 6, 2024 | REAL ESTATE NEWS</h5>
        <p>
          From Connectivity to Prosperity: Unveiling Noida's Infrastructure
          Marvel From Connectivity to Prosperity: Unveiling Noida's
          Infrastructure Marvel
        </p>
        <button className="btn btn-outline-dark border rounded-4 w-75 mx-auto mb-3">
          Explore
        </button>
      </div>
    </div>
  </section>;
};
