import React from "react";
// import { useNavigate } from "react-router-dom";
import MultiSelect from "./MultiSelect";

const AMENITIES_LIST = [
  "24 Hour Water Supply",
  "9 Golf Course",
  "Basketball Court",
  "Bosques",
  "Car Parking",
  "CCTV Surveillance",
  "Club House",
  "Courtyards",
  "Cricket",
  "Fire Exit",
  "Fire Fighting Systems",
  "Garden",
  "Gymnasium",
  "Indoor Games",
  "Intercom Facility",
  "Jogging / Cycle Track",
  "Kids' Play Areas / Sand Pits",
  "Kids Splash Pool",
  "Landscaping",
  "Lift Available",
  "Metro",
  "Open Air Theater",
  "Park",
  "Power Backup",
  "Rain Water Harvesting",
  "Security",
  "Swimming Pool",
  "Terrace Garden",
  "Water Fountain",
  "Yoga Center",
];

const AmenitiesFilter = ({ amenities, setAmenities }) => {
  // const navigate = useNavigate();
  // const [selectedAmenities, setSelectedAmenities] = useState([]);

  // useEffect(() => {
  //   if (selectedAmenities.length) {
  //     setAmenities(selectedAmenities);
  //   }
  // }, [selectedAmenities, setAmenities]);

  //   const queryParams = updatedAmenities
  //         .map((amenity, index) => `filters[$and][${index}][amenities][Title][$eq]=${encodeURIComponent(amenity)}`)
  //         .join("&");

  //       const url = `http://localhost:1337/api/projects?${queryParams}&populate=*&sort=projectSequence:ASC&pagination[page]=1`;

  return (
    <div className="my-4 ">
      <MultiSelect
        label="Amenities"
        options={AMENITIES_LIST}
        selectedValues={amenities}
        setSelectedValues={setAmenities}
      />
    </div>
  );
};

export default AmenitiesFilter;
