const ConnectivityNearAreas = ({ connectedAreas }) => {
  const CONNECTIVITY = connectedAreas;
  return (
    <div className="connectivityContainer">
      {CONNECTIVITY?.map((connected, idx) => {
        return (
          connected?.area && (
            <div key={idx} className="connectivityBox">
              <img
                src="https://bold-approval-c005df0fb8.media.strapiapp.com/cctv_camera_a0b88b2052.png"
                alt="cctv_camera_a0b88b2052"
                title="cctv_camera"
                loading="lazy"
                className="img-fluid"
              />
              <div className="connectivityText">{connected?.area}</div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default ConnectivityNearAreas;
