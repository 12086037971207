import React, { memo, useState, useEffect } from "react";
import CareerAccordionCard from "../CareerAccordionCard/";
import CarrerAccordianRightCard from "./CarrerAccordianRightCard";
import useDeviceSize from "../../../../../hooks/useDeviceSize";
const CareerAccordionCardList = memo(
  ({ filteredPositions, callBackHandleJobForm }) => {
    const [selectedPostion, setSelectedPosition] = useState();
    const [selectedPostionName, setSelectedPositionName] = useState(null);
    const [activeCard, setActiveCard] = useState(0);
    const isMobile = useDeviceSize();
    useEffect(() => {
      if (filteredPositions?.length > 0) {
        setSelectedPosition(
          filteredPositions[0]?.attributes?.Position_Description
        );
        setSelectedPositionName(
          filteredPositions[0]?.attributes?.Position_Name
        );
      }
    }, [filteredPositions]);

    return (
      <div className="row mt-5 align-items-center">
        <div
          className="col-lg-4 bg-white shadow p-4 rounded-4 mb-4"
          style={{
            background: "#F4F4F4",
            height: isMobile ? "300px" : "400px",
            overflowY: "auto",
          }}
        >
          {filteredPositions?.map((position, idx) => {
            const { Position_Name } = position?.attributes;
            // console.log("this is position", Position_Name);

            return (
              <CareerAccordionCard
                key={idx}
                index={idx}
                position={position}
                handleForm={() => callBackHandleJobForm(Position_Name)}
                setSelectedPosition={setSelectedPosition}
                setSelectedPositionName={setSelectedPositionName}
                activeCard={activeCard}
                setActiveCard={setActiveCard}
              />
            );
          })}
        </div>

        <CarrerAccordianRightCard
          selectedPostion={selectedPostion}
          handleForm={() => callBackHandleJobForm(selectedPostionName)}
        />
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.filteredPositions === nextProps.filteredPositions
);

export default CareerAccordionCardList;
