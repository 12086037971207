import React from "react";
// import { CITY_NAME } from "../../../../assets/IMG";
import MultiSelect from "./MultiSelect";

const PropertyFiltersByCities = ({
  selectedCities,
  setSelectedCities,
  options,
}) => {
  // const [selectedCities, setSelectedCitiesState] = useState([]);

  // useEffect(() => {
  //   if (selectedCities.length) {
  //     setSelectedCities(selectedCities);
  //   }
  // }, [selectedCities, setSelectedCities]);

  //   const handleSelectionChange = (city) => {
  //     setLocalSelectedCities((prev) => {
  //       let updatedCities;
  //       if (prev.includes(city)) {
  //         updatedCities = prev.filter((item) => item !== city);
  //       } else {
  //         updatedCities = [...prev, city];
  //       }
  //       return updatedCities;
  //     });
  //   };
  // console.log("selectedCities", selectedCities);

  return (
    <div className="my-4">
      <MultiSelect
        label="Location"
        options={options}
        selectedValues={selectedCities}
        setSelectedValues={setSelectedCities}
      />
    </div>
  );
};

export default PropertyFiltersByCities;
