import { useState } from "react";
import Heading from "../../globalComponents/molecules/Heading";
import useApiFetcher from "../../../hooks/useApiFetcher";
import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
import SubscribeSection from "../../homePage/SubcribeNewslater/SubscribeSection";
import { getYoutubeEmbedUrl } from "../../../services/youtubeLinkEmbedConverter";
import { EMPTY_OBJECT } from "../../../assets/constants";
import "./happyCustomer.css";
import useDeviceSize from "../../../hooks/useDeviceSize";

const url = getFullUrl(`${API_URL.HAPPY_CUSTOMER}${API_URL.POPULATE}`);

const HappyCustomer = () => {
  const isMobile = useDeviceSize();
  const [happyCustomer, error, isLoading] = useApiFetcher(url);
  const [selectedVideo, setSelectedVideo] = useState(null); // To store the selected video

  if (error) return <div>{error.message}</div>;
  if (!happyCustomer && isLoading) return <div>Reviews Loading...</div>;

  const CUSTOMER_VIDEO_URL = happyCustomer;

  let ytUrl =
    selectedVideo ||
    CUSTOMER_VIDEO_URL?.[0]?.attributes?.YouTube_link ||
    EMPTY_OBJECT;

  return (
    <>
      <div className="container-fluid ">
        <Heading
          text={"Reviews"}
          color={"black"}
          className="fw-bold mt-5 mb-4 text-center"
        />

        <div className="row justify-content-lg-around  happy-customer">
          <div className="row ">
            <div className="col-8">
              <div className="w-100 ">
                <iframe
                  title="YouTube Video"
                  src={getYoutubeEmbedUrl(ytUrl) || ""}
                  allowFullScreen
                  className="main-video  "
                />
              </div>
            </div>
            <div
              className="col-4  overflow-y-scroll overflow-hidden"
              style={{ height: isMobile ? "300px" : "700px" }}
            >
              {CUSTOMER_VIDEO_URL?.map((item, idx) => (
                <Testimonials
                  key={idx}
                  className=" rounded border border-secondary w-100"
                  item={item}
                  style={{
                    objectFit: "contain",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setSelectedVideo(item?.attributes?.YouTube_link)
                  }
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <SubscribeSection />
    </>
  );
};

export default HappyCustomer;

export function Testimonials({ item, onClick }) {
  const { YouTube_Thumbnail = "" } = item?.attributes || EMPTY_OBJECT;
  const { url = "" } = YouTube_Thumbnail?.data?.attributes || EMPTY_OBJECT;

  return (
    <div className="thumbnail-item" onClick={onClick}>
      <img alt="Thumbnail" src={url} className="thumbnail-image" />
    </div>
  );
}
