// import { dropDownSlice } from "./../../../Redux/dropDownSlice";
import { cloneElement, useState } from "react";
import "./dropDown.css";

const CustomDropDown = ({
  selectType,
  data,
  children,
  styleMenu,
  styleBtn,
  text,
  isDropDownOpen,
  setIsDropDownOpen,
  dropDownValueKeyName,
  setDropDownValue,
  ...props
}) => {
  // const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [value, setValue] = useState(text);
  const [timeoutId, setTimeoutId] = useState(null);
  const [hoveredCity, setHoveredCity] = useState(data?.[0] ?? {});

  const handleBtn = () => {
    clearTimeout(timeoutId);
    setIsDropDownOpen(!isDropDownOpen);
  };

  const handleCloseDropDown = () => {
    if (isDropDownOpen)
      return setTimeoutId(setTimeout(() => setIsDropDownOpen(false), 250));
  };
  // console.log("data", data);
  return (
    <div
      className="customDropDown"
      onMouseLeave={handleCloseDropDown}
      {...props}
    >
      <button
        className="d-flex justify-content-between"
        onClick={handleBtn}
        style={styleBtn}
      >
        <span className={`${isDropDownOpen && "ms-5 ms-lg-0"}`}>{value}</span>
        <div>
          <i
            className={`fa fa-chevron-right icon ms-2 ${
              isDropDownOpen && "open"
            }`}
          ></i>
        </div>
      </button>
      <div
        onMouseEnter={() => clearTimeout(timeoutId)}
        style={styleMenu}
        className={` ${
          isDropDownOpen ? "dropDown-MenuList_active" : "dropDown-MenuList"
        }`}
      >
        <div className="container">
          <div className="row p-0 p-md-4">
            <div
              className={`col-md-6 col-12 ${
                isDropDownOpen && "ms-5 ms-lg-0 mt-2"
              }`}
            >
              <h4 className="fs-4 ">Select Your City</h4>
              <div className="row">
                {data.map((item, idx) => (
                  <div key={idx} className="col-md-6 col-6 my-2">
                    <div
                      className="MenuItem"
                      onMouseOver={() => setHoveredCity(item)}
                      onClick={
                        selectType === "enabled"
                          ? function updateDropDownValue() {
                              return setValue(
                                item?.cityName ? item?.cityName : item
                              );
                            }
                          : () =>
                              setDropDownValue
                                ? getSelectedBtnValue(
                                    setDropDownValue,
                                    dropDownValueKeyName,
                                    item?.cityName ? item?.cityName : item
                                  )
                                : null
                      }
                    >
                      {children
                        ? cloneElement(children, {
                            text: item?.cityName ? item?.cityName : item,
                          })
                        : item?.cityName
                        ? item?.cityName
                        : item}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 pt-5 d-none d-md-block">
              <img
                src={hoveredCity?.cityImageUrl}
                alt={hoveredCity?.cityName}
                loading="lazy"
                // onError={}
                className="img-fluid rounded w-100 "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDropDown;

// -----------------------------------------------------

function delItemFromArr(arrayKey, item) {
  return arrayKey.filter((ele) => ele !== item);
}

function getSelectedBtnValue(setDropDownValue, dropDownValueKeyName, item) {
  const updatePrevState = (prevState) => {
    if (Array.isArray(prevState[dropDownValueKeyName])) {
      const arrayKey = prevState[dropDownValueKeyName];
      const updatedArr = arrayKey.includes(item)
        ? delItemFromArr(arrayKey, item)
        : [...arrayKey, item];

      return {
        ...prevState,
        [dropDownValueKeyName]: updatedArr,
      };
    }

    return { ...prevState, [dropDownValueKeyName]: item };
  };
  return setDropDownValue(updatePrevState);
}
