import React from "react";
import { BUILDING_TYPE } from "../../../../assets/IMG";
import MultiSelect from "./MultiSelect";

const PropertyTypeFilter = ({ propertyType, setPropertyType }) => {
  // const [selectedPropertyTypes, setSelectedPropertyTypes] = useState([]);

  // useEffect(() => {
  //   if (selectedPropertyTypes.length) {
  //     setPropertyType(selectedPropertyTypes);
  //   }
  // }, [selectedPropertyTypes, setPropertyType]);

  // const handleSelectionChange = (type) => {
  //   setSelectedPropertyTypes((prev) => {
  //     let updatedTypes;
  //     if (prev.includes(type)) {
  //       updatedTypes = prev.filter((item) => item !== type);
  //     } else {
  //       updatedTypes = [...prev, type];
  //     }
  //     return updatedTypes;
  //   });
  // };

  return (
    <div className="my-4">
      <MultiSelect
        label="Type of Property"
        options={BUILDING_TYPE}
        selectedValues={propertyType}
        setSelectedValues={setPropertyType}
      />
    </div>
  );
};

export default PropertyTypeFilter;
