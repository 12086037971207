import Header from "../../globalComponents/header";
import Heading from "../../globalComponents/molecules/Heading";
import HeaderMob from "./../../mobileComponents/HeaderMob";
import AdvancedSearchBar from "../../advancedSearchBar/AdvancedSearchBar";
// import VIDEO_URL from "../../../assets/videourl.mp4";
import TemplateCard from "./TemplateCard";

import useDeviceSize from "../../../hooks/useDeviceSize";
import "./newTemplateSlider.css";

const NewTemplateSlider = ({ searchBar, text, style, height, textImg }) => {
  const isMobile = useDeviceSize();
  let newStyle = style;
  let heightIs = height ? height : isMobile ? "228px" : "80vh";

  return (
    <>
      <section className="container-fluid p-0">
        <div
          className="video-container"
          style={{ height: heightIs, ...newStyle }}
        >
          <div className="templateContainer">
            <TemplateCard imgHeight={heightIs} />
          </div>

          <div className="content-on-top">
            <div className="header-container">
              {isMobile ? <HeaderMob /> : <Header />}
            </div>

            {!isMobile && (
              <div className="searchBar-container">
                {text && text !== "" ? (
                  <Heading
                    tag="h1"
                    text={text}
                    fontSize="2.5rem"
                    fontWeight="700"
                    color="#fff"
                    className="text-center text-capitalize fs-1"
                  />
                ) : null}

                {searchBar === "enabled" ? (
                  <div className="justify-content-center">
                    <AdvancedSearchBar />
                  </div>
                ) : null}

                <p className="text-white text-center custom-font ">
                  The Most Followed Real Estate Consultant in India
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      {isMobile && (
        <section className="search-section p-3 ">
          <div className="container text-center">
            {text && text !== "" && (
              <Heading
                tag="h1"
                text={text}
                fontSize="2rem"
                fontWeight="700"
                color="black"
                className="text-center text-capitalize"
              />
            )}

            {searchBar === "enabled" && (
              <div className="mt-3">
                <AdvancedSearchBar />
              </div>
            )}

            <p className="text-black text-center custom-font fs-md-4 mt-4">
              The Most Followed Real Estate Consultant in India
            </p>
          </div>
        </section>
      )}
    </>
  );
};

export default NewTemplateSlider;
