import React, { useCallback, useEffect, useState } from "react";
import // PROPERTY_TYPES,
// SORTED_BY,
"../../../../assets/constants/filters";
import { useNavigate } from "react-router-dom";
// import useApiFetcher from "../../../../hooks/useApiFetcher";
import // API_URL,
// generateSortedUrl,
"../../../../assets/constants/apiUrls";
import BudgetFilter from "../propertyFilters/BudgetFilter";
import AmenitiesFilter from "../propertyFilters/AmenitiesFilter";
import PropertyTypeFilter from "../propertyFilters/PropertyTypeFilter";
import PropertyFiltersByCities from "../propertyFilters/PropertyFiltersByCities";
import { CITY_NAME } from "../../../../assets/IMG";

const INITIAL_FILTERS = {
  cityName: [],
  selectType: [],
  budget: { minBudget: 0, maxBudget: 0 },
  amenities: [],
};

const Filters = () => {
  const navigate = useNavigate();
  const [dropDownValue, setDropDownValue] = useState(INITIAL_FILTERS);

  // Check if any filters are applied
  const isFiltersApplied = useCallback(() => {
    const { cityName, selectType, budget, amenities } = dropDownValue;
    return (
      cityName.length > 0 ||
      selectType.length > 0 ||
      budget?.minBudget > 0 ||
      budget?.maxBudget > 0 ||
      amenities.length > 0
    );
  }, [dropDownValue]); // ✅ Added dependencies

  // 🚀 Prevent unnecessary navigation calls
  useEffect(() => {
    if (isFiltersApplied()) {
      navigate("/search", { state: dropDownValue });
    }
  }, [dropDownValue, navigate, isFiltersApplied]); // ✅ Added `isFiltersApplied` dependency

  // Update filter values
  const handleFilterChange = (filterName, value) => {
    setDropDownValue((prevValues) => ({
      ...prevValues,
      [filterName]: value,
    }));
  };

  // Clear all filters and reset state
  const clearFilters = () => {
    setDropDownValue(INITIAL_FILTERS);
    navigate("/search", { state: INITIAL_FILTERS });
  };

  return (
    <div className="p-3 px-4 border rounded-5 bg-white shadow rounded-4">
      <h5 className="text-orange fw-bold fs-3 " style={{ color: "#EF750F" }}>
        Filters
      </h5>
      {/* Find City */}
      <PropertyFiltersByCities
        selectedCities={dropDownValue.cityName}
        options={CITY_NAME}
        setSelectedCities={(value) => handleFilterChange("cityName", value)}
      />
      {/* Property Type */}
      <PropertyTypeFilter
        propertyType={dropDownValue.selectType}
        setPropertyType={(value) => handleFilterChange("selectType", value)}
      />

      {/* Amenities */}
      <AmenitiesFilter
        amenities={dropDownValue.amenities}
        setAmenities={(value) => handleFilterChange("amenities", value)}
      />

      {/* Budget Filter */}
      <BudgetFilter
        budget={dropDownValue.budget}
        setBudget={(value) => handleFilterChange("budget", value)}
      />

      {/* Clear Filters Button */}
      <button
        className="py-2 rounded-pill border-0  w-100 mt-2 "
        onClick={clearFilters}
        disabled={!isFiltersApplied()} // Disable if no filters applied
        style={{
          cursor: isFiltersApplied() ? "pointer" : "not-allowed",
          color: isFiltersApplied() ? "white" : "black",
          background: isFiltersApplied() ? "#EF750F" : "#ccc",
          // opacity: isFiltersApplied() ? "1" : "0.9",
        }}
      >
        Clear all filters
      </button>
    </div>
  );
};

export default Filters;
