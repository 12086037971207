import { FaCalendarDays } from "react-icons/fa6";
// import { MdOutlineWatchLater } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";
// import Heading from "../../../globalComponents/molecules/Heading";

import {
  splitDate,
  getMonthName,
  checkTimeShift,
} from "../../../../helpers/helper";
import styles from "../../blogPage/blogsComponents/blogCard2.module.css";

export const NewEventCard = ({ cardData }) => {
  // const { img, time, fullDate, date, month, details } = cardData;
  console.log("carddata", cardData);

  const {
    Featured_Image,
    Start_Date,
    Venue,
    Enter_The_Time,
    // createdAt,
    Title,
    Event_Description,
  } = cardData?.attributes;
  const { url } = Featured_Image?.data?.attributes;

  const [img, fullDate, details, time] = [
    url,
    Start_Date,
    Venue,
    Enter_The_Time,
  ];
  const [year, month, date] = splitDate(fullDate);

  const customMonth = getMonthName(month).slice(0, 3);
  // const customDate = `${year}-${customMonth}-${date}`;
  const customTime = `${checkTimeShift(time)}`;
  const getFullWeekdayName = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(date);
  };

  // const textContainer = {
  //   margin: "0.25rem",
  // };
  // function formatDate(dateString) {
  //   const date = new Date(dateString);

  //   const month = date.toLocaleString("en-US", { month: "short" });
  //   const day = date.getDate();
  //   const weekday = date.toLocaleString("en-US", { weekday: "long" });
  //   const time = date.toLocaleTimeString("en-US", { hour12: false });

  //   return { month, day, weekday, time };
  // }
  return (
    <div className={styles.blogCard}>
      <div className={styles.blogCardImageBox}>
        <img alt="blogcardimage" src={img} />
      </div>

      <div className="pt-2">
        <h5 className="text-bold text-dark">{Title}</h5>
        <p className="pt-0">
          {Event_Description?.length > 0
            ? Event_Description[0]?.children[0]?.text
                .split(" ")
                .slice(0, 17)
                .join(" ")
            : ""}
        </p>
      </div>

      <div className="row d-flex  align-items-start  w-100 justify-content-between">
        <div className="col-9 d-flex flex-column">
          <div className="d-flex align-items-center mb-2 ">
            <FaCalendarDays
              className="me-2 fs-5"
              style={{ color: "#ef750f" }}
            />
            <span>
              {getFullWeekdayName(fullDate)} , <span>{customTime}</span>
            </span>
          </div>

          <div className="d-flex align-items-center mb-2">
            <MdOutlineLocationOn
              className="fs-4 me-2"
              style={{ color: "#ef750f" }}
            />
            <span>{details}</span>
          </div>
        </div>
        {/* <div>
          <p>{formatDate(createdAt).month} ,</p>
          <p>{formatDate(createdAt).day} ,</p>
          <p>{formatDate(createdAt).weekday} ,</p>
          <p>{formatDate(createdAt).time}</p>
        </div> */}
        <div
          style={{
            height: "50px",
            width: "50px",
            borderRadius: "50%",
            border: "2px solid #ef750f",
          }}
          className="col-3 bg-dark text-white d-flex align-items-center flex-column justify-content-center  gap-0"
        >
          <p className="p-0 m-0">{date}</p>
          <p className="p-0 m-0">{customMonth}</p>
        </div>
      </div>

      <button className={`${styles.blogCardBtn} fw-bold mt-3`}>
        Read More
      </button>
    </div>
  );
};
