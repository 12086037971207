import Header from "../../globalComponents/header/Header";
import Heading from "../../globalComponents/molecules/Heading";
import HeaderMob from "../../mobileComponents/HeaderMob";
import AdvancedSearchBar from "../../advancedSearchBar/AdvancedSearchBar";
import useDeviceSize from "../../../hooks/useDeviceSize";
import "./newTemplateSlider.css";
import TEMPLATE_BACKGROUND from "../../../../src/components/homePage/templateSlider/templateBackground.webp";

import LUCKNOW from "../../../assets/Lucknow.jpg";
import NOIDA from "../../../assets/Noida.jpeg";
import GREATERNOIDA from "../../../assets/GreaterNoida.jpg";

import MORADABAD from "../../../assets//moradabad.webp";
import PUNE from "../../../assets/pune.jpeg";

// import GHAZIABAD from "../../../assets/Ghaziabad.png";
import GHAZIABAD from "../../../assets/Ghaziabad.webp";

import RISHIKESH from "../../../assets/Risheksh.jpg";
import DELHI from "../../../assets/delhi.jpg";
import GURUGRAM from "../../../assets/gurugram.jpg";
import BENGALURU from "../../../assets/bengluru.jpeg";

// import GREATERNOIDA from "../../../assets/GreaterNoida1.png";

import { useParams } from "react-router-dom";
import { slugToText } from "../../../services/slug";

export const cityBackgrounds = {
  moradabad: MORADABAD,
  pune: PUNE,
  lucknow: LUCKNOW,
  ghaziabad: GHAZIABAD,
  noida: NOIDA,
  rishikesh: RISHIKESH,
  delhi: DELHI,
  gurugram: GURUGRAM,
  bengaluru: BENGALURU,
  "greater-noida": GREATERNOIDA,
};

const TemplateSlider = ({ text, searchBar, bgImage }) => {
  const isMobile = useDeviceSize();
  const { city } = useParams();
  const textName = city ? `New Projects in ${slugToText(city)}` : text;

  const cityName = city?.toLowerCase();
  const backgroundImage =
    cityBackgrounds[cityName] || bgImage || TEMPLATE_BACKGROUND;

  const isVideo = typeof bgImage === "string" && bgImage.match(/\.mp4$/);

  return (
    <section className="container-fluid p-0">
      <div className="content-on-top">
        <div className="header-container">
          {isMobile ? <HeaderMob /> : <Header />}
        </div>

        <div
          style={{
            height: isMobile ? "50vh" : "70vh",
            width: "100%",
            position: "relative",
          }}
        >
          {isVideo ? (
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                filter: "brightness(30%)",
                loading: "lazy",
              }}
            >
              <source src={bgImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <div
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                height: "100%",
                width: "100%",
                position: "absolute",
                filter: "brightness(30%)",
                top: 0,
                left: 0,
                loading: "lazy",
              }}
            ></div>
          )}

          <div
            className="searchBar-container"
            style={{ position: "relative", zIndex: 1 }}
          >
            {!!textName && (
              <Heading
                tag="h1"
                text={textName}
                fontSize="2.5rem"
                fontWeight="900"
                color="#fff"
                className="text-center text-capitalize fs-1"
              />
            )}

            {searchBar === "enabled" && (
              <div className="d-flex justify-content-center">
                <AdvancedSearchBar />
              </div>
            )}

            <h6 className="text-white text-center custom-font fs-md-lg">
              The Most Followed Real Estate Consultant in India
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TemplateSlider;

export const ImgCard = ({ imgHeight, bgImage }) => {
  return (
    <div
      className="templateSliderContainer"
      style={{
        height: imgHeight || "100vh",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        filter: "brightness(30%)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    ></div>
  );
};
