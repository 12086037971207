import { memo } from "react";
import Heading from "../../../globalComponents/molecules/Heading";

const AwardCard = memo(({ awardData, activeTabId }) => {
  const { img, title, description } = awardData;

  return (
    <div className="col-md-3 col-sm-6 col-12 p-2">
      <div className="awardCard position-relative shadow rounded overflow-hidden">
        <div className="award-img position-relative">
          {/* Image with background gradient */}
          <img
            src={img}
            alt="Award"
            loading="lazy"
            className="w-100 h-100 img-fluid object-fit-cover"
          />
          {activeTabId === "Company Achievements" && (
            <div
              className="position-absolute top-0 start-0 w-100 h-100  text-white"
              style={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%)",
              }}
            ></div>
          )}

          {/* Title */}
          {/* {title && (
            <Heading
              text={title}
              fontSize="1rem"
              lineHeight="1.25rem"
              showLine="1"
              className="fw-bold text-white position-absolute"
              style={{
                bottom: "10px",
                left: "10px",
              }}
            />
          )} */}

          {/* Description */}
          {description && (
            <p
              className="text-white position-absolute"
              style={{
                bottom: "30px",
                left: "10px",
                fontSize: "0.75rem",
                lineHeight: "1rem",
                maxWidth: "80%",
                margin: 0,
              }}
            >
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
});

export default AwardCard;
