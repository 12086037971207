import { Link } from "react-router-dom";
import { useState } from "react";
import { IoSearchOutline } from "react-icons/io5";

import SearchSuggestionBar from "./SearchSuggestionBar";
// import BudgetSelect from "./BudgetSelect";
// import MultiSelectDropdown from "./../MultiSelectDropdown";
// import useDeviceSize from "../../hooks/useDeviceSize";
// import { CITY_NAME, BUILDING_TYPE } from "../../assets/IMG";
import { EMPTY_OBJECT } from "./../../assets/constants/index";
import "./AdvancedSearchBar.css";

// const MemoMultiSelectDropdown = memo(MultiSelectDropdown);
// const MemoBudgetSelect = memo(BudgetSelect);

// const DROP_DOWNS_TYPE = {
//   cityName: [],
//   selectType: [],
//   budget: "",
// };

const AdvancedSearchBar = () => {
  // const [dropDownValue, setDropDownValue] = useState(DROP_DOWNS_TYPE);
  const [searchBarValue, setSearchedBarValue] = useState(EMPTY_OBJECT);
  // const [budget, setBudget] = useState(null);

  // const handleCitySelection = useCallback((data) => {
  //   setDropDownValue((older) => ({ ...older, cityName: data }));
  // }, []);

  // const handleTypeSelection = useCallback((data) => {
  //   setDropDownValue((older) => ({ ...older, selectType: data }));
  // }, []);
  // console.log("budget", budget);
  // const isMobile = useDeviceSize;
  return (
    <header className="my-2 advancedSearchBar mx-auto rounded-pill">
      {/* <div className="col-md-5 d-flex">
        <div className="col-md-4 mr-3 d-none d-lg-block">
          <MemoMultiSelectDropdown
            name={"Select City"}
            data={CITY_NAME}
            cb={handleCitySelection}
          />
        </div>

        <div className="col-md-3 mr-3 d-none d-lg-block border">
          <MemoMultiSelectDropdown
            name={"Type"}
            data={BUILDING_TYPE}
            cb={handleTypeSelection}
          />
        </div>

        <div className="col-md-5 border d-none d-lg-block">
          <MemoBudgetSelect getBudget={setBudget} />
        </div>
      </div> */}

      <div className="col-md-5 w-75">
        <SearchSuggestionBar getSearchedValue={setSearchedBarValue} />
      </div>

      <div className=" btn-width  rounded-end-pill">
        {/* <button
          className="fw-bold text-white "
        
        > */}
        <Link
          to={"/search"}
          state={{ searchBarValue }}
          className="text-decoration-none text-white w-100 d-flex align-items-center justify-content-center h-100 rounded-end-pill"
          style={{ background: "var(--global-text-color)" }}
        >
          <span className=" d-none d-md-block">Search</span>
          <IoSearchOutline className="d-block d-md-none" size={18} />
        </Link>
        {/* </button> */}
      </div>
    </header>
  );
};

export default AdvancedSearchBar;
