import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Scrollbar, Zoom } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import videos from ".././../../assets/videosTestmonilas/vide0s.jpg";
import videos1 from ".././../../assets/videosTestmonilas/Videos2.jpg";
import videos2 from ".././../../assets/videosTestmonilas/videos3.jpg";
import videos4 from ".././../../assets/videosTestmonilas/videos4.jpg";
import videos5 from ".././../../assets/videosTestmonilas/videos5.jpg";
import videos6 from ".././../../assets/videosTestmonilas/videos6.jpg";
import videos7 from ".././../../assets/videosTestmonilas/video7.jpg";
import videos8 from ".././../../assets/videosTestmonilas/videos8.jpg";
import { FaTimes } from "react-icons/fa";

const ClientCard = ({ card, openModal, isActive }) => {
  return (
    <div
      className="p-4  d-flex align-items-center justify-content-center my-5"
      style={{
        transform: isActive ? "scale(1.3)" : "scale(1)",
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <div className="card text-center bg-white shadow w-100 position-relative p-0 ">
        <div className="card-body position-relative ">
          <img
            src={card.thumbnail}
            alt="Video Thumbnail"
            className="w-100 h-100 rounded-4 border border-secondary"
            style={{
              objectFit: "cover",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() => openModal(card.video)}
          />
          <button
            onClick={() => openModal(card.video)}
            className="play-button position-absolute top-50 start-50 translate-middle bg-dark text-white border-0 rounded-circle d-flex align-items-center justify-content-center transition-all duration-300 hover:bg-warning"
            style={{
              width: "80px",
              height: "80px",
              fontSize: "20px",
            }}
          >
            ▶
          </button>
        </div>
      </div>
    </div>
  );
};

const VideoTestimonial = () => {
  const [videoModal, setVideoModal] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const clientCards = [
    {
      video:
        "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/1-TestimonialsVideo.mp4",
      thumbnail: videos,
    },
    {
      video:
        "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/2-TestimonialsVideo.mp4",
      thumbnail: videos1,
    },
    {
      video:
        "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/3-TestimonialsVideo.mp4",
      thumbnail: videos2,
    },
    {
      video:
        "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/4-TestimonialsVideo.mp4",
      thumbnail: videos4,
    },
    {
      video:
        "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/5-TestimonialsVideo.mp4",
      thumbnail: videos5,
    },
    {
      video:
        "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/6-TestimonialsVideoFinal.mp4",
      thumbnail: videos6,
    },
    {
      video:
        "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/7-TestimonialsFinal.mp4",
      thumbnail: videos7,
    },
    {
      video:
        "https://adsrole.s3.ap-south-1.amazonaws.com/wealth-clinic/prod/8-TestimonialsVideoFinal.mp4",
      thumbnail: videos8,
    },
  ];

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update();
    }
  }, []);

  return (
    <div className="container text-center mt-5 pb-5 p-4">
      <div className="d-flex align-items-center justify-content-center">
        <h2 className="fw-bold text-center mb-0">Video Testimonials</h2>
      </div>
      <div className="mt-5 mb-0">
        <Swiper
          breakpoints={{
            640: { slidesPerView: 1, centeredSlides: true },
            768: { slidesPerView: 1, centeredSlides: true },
            1024: { slidesPerView: 3, centeredSlides: true },
          }}
          loop={true}
          speed={2000}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          scrollbar={{ draggable: false }}
          modules={[Autoplay, Zoom, Scrollbar, Pagination]}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
          className="px-4 px-lg-0"
        >
          {clientCards.map((card, i) => (
            <SwiperSlide
              key={i}
              className="py-5 pt-0 d-flex align-items-center px-0 px-lg-4 justify-content-start "
            >
              <ClientCard
                card={card}
                openModal={setVideoModal}
                isActive={i === activeIndex}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="mt-4">
        <button
          onClick={() => swiperRef.current?.slidePrev()}
          className="btn border border-2 border-black rounded-circle me-2 bg-transparent"
        >
          &lt;
        </button>
        <button
          onClick={() => swiperRef.current?.slideNext()}
          className="btn border border-2 border-black rounded-circle bg-transparent"
        >
          &gt;
        </button>
      </div>

      {/* Video Modal */}
      {videoModal && (
        <div
          className="video-modal d-flex align-items-center justify-content-center"
          onClick={() => setVideoModal(null)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.8)",
            zIndex: 1050,
          }}
        >
          <div
            className="video-modal-content position-relative p-3"
            style={{ maxWidth: "70%", maxHeight: "70%" }}
          >
            <button
              className="close-button position-absolute top-0 end-0 m-3"
              onClick={() => setVideoModal(null)}
              style={{
                background: "#f27d55",
                color: "white",
                border: "none",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                zIndex: 1100,
              }}
            >
              <FaTimes />
            </button>
            <video
              controls
              autoPlay
              style={{ width: "100%", borderRadius: "8px" }}
            >
              <source src={videoModal} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoTestimonial;
