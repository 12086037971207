import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

// import MetaTagHelmet from "../../globalComponents/MetaTagHelmet/MetaTagHelmet";
// import BreadCrumb from "../../globalComponents/BreadCrumb";
import Heading from "../../globalComponents/molecules/Heading";
import MetaTagHelmet from "../../globalComponents/MetaTagHelmet";
import PaginationCustom from "../../globalComponents/PaginationCustom/PaginationCustom";
// import PropertyForm from "./PropertyForm";
import PropertyCard from "./propertyCard";
// import PropertyPageToolBar from "./PropertyPageToolBar";
import Loader from "../../Loader";

import useApiFetcher from "../../../hooks/useApiFetcher";
// import useMetaTags from "../../../hooks/useMetaTags";
import { slugToText } from "../../../services/slug";

import {
  getFullUrl,
  generateSearchedBarUrl,
  getSortQueryByField,
  API_URL,
} from "../../../assets/constants/apiUrls";
import useDeviceSize from "../../../hooks/useDeviceSize";
import useScrollOnTop from "../../../hooks/useScrollOnTop";
import URL, { URL_PARAMS } from "../../../assets/constants/url";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../assets/constants/index";
import { SORTED_BY_OPTIONS } from "../../../assets/constants/filters";
import useMetaTagsInArray from "../../../hooks/useMetaTagsInArray";
import "./cityPages.css";
import PropertyFilters from "./PropertyForm/PropertyFilters";
import axios from "axios";
import { Api } from "@mui/icons-material";
import PropertyPageToolBar from "./PropertyPageToolBar/PropertyPageToolBar";

// const urlForAllProperties = getFullUrl(API_URL.PROPERTIES + API_URL.POPULATE);

const CityPages = ({ setCityName, defaultFilters }) => {
  // const CITY_PAGE_API = {
  //   NEW_LAUNCHES: `${API_URL?.PROPERTIES}?filters[newLaunches]=true`,
  //   EXCLUSIVE_PROPERTY: `${API_URL?.PROPERTIES}?filters[exclusive]=true`,
  //   CATEGORY: () =>
  //     `${
  //       API_URL.PROPERTIES
  //     }?filters[$and][0][property_type][Title][$eqi]=${slugToText(
  //       category
  //     )}&populate=*`,
  // };
  const isMobile = useDeviceSize();
  let { city: selectedCity } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("location", location);
  // console.log("selectedCity", selectedCity);
  // console.log("paramsss", useParams());
  console.log("defaultFilters", defaultFilters);

  const category = searchParams.get(URL_PARAMS.CATEGORY);
  const propertyType = searchParams.get(URL_PARAMS.PROPERTY_TYPE);
  const exclusiveProperty = searchParams.get(URL_PARAMS.EXCLUSIVE_PROPERTY);
  const newLaunches = searchParams.get(URL_PARAMS.NEW_LAUNCHES);
  const searchTerm = searchParams.get(URL_PARAMS.SEARCH_TERM);
  const buildingType = searchParams.get(URL_PARAMS.BUILDING_TYPE);
  const pageNumber = searchParams.get("page") || 1;
  const [propertiesData, setPropertiesData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useScrollOnTop(670, 0, location?.pathname + location?.search);

  // https://bold-approval-c005df0fb8.strapiapp.com/api/projects?filters[sub_categories][Title][$eqi][0]=Farmland&populate=*&sort=projectSequence:ASC&pagination[page]=1
  // https://bold-approval-c005df0fb8.strapiapp.com/api/projects?filters[create_city][City_Name][$eqi][0]=Greater%20Noida&populate=*&sort=projectSequence:ASC&pagination[page]=1
  const [filterBySortByOrder, setSortOrder] = useState(null);
  // const [filterBypropertyType, setFilterByPropertyType] = useState(null);
  const [apiUrl, setApiUrl] = useState("");

  const [allCities] = useApiFetcher(getFullUrl(API_URL?.CITY_NAME));

  const { title, description, link } = useMetaTagsInArray(
    `/api/create-cities?filters[City_Name][$eqi]=${selectedCity}`
  );

  // console.log("apiUrl apiUrl apiUrl", apiUrl);

  useEffect(() => {
    if (apiUrl) {
      const getProperTies = async () => {
        try {
          setLoading(true);
          setPropertiesData([]); // Clear previous data before fetching new data
          const res = await axios.get(apiUrl);
          console.log("Response:", res);
          if (res.status === 200) {
            setPropertiesData(res.data.data);
            setPageCount(res.data.meta.pagination.pageCount);
            setError("");
          }
        } catch (error) {
          console.error("Error fetching properties:", error);
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      getProperTies();
    }
  }, [apiUrl]);
  // useEffect(() => {
  //   if (apiUrl) {
  //     setLoading(true);
  //     setPropertiesData([]); // Clears old data

  //     axios
  //       .get(apiUrl)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setPropertiesData(res.data.data);
  //           setPageCount(res.data.meta.pagination.pageCount);
  //           setError("");
  //         }
  //       })
  //       .catch((err) => {
  //         console.error("Error fetching properties:", err);
  //         setError(err.message);
  //       })
  //       .finally(() => setLoading(false));
  //   }
  // }, [apiUrl]);

  // useEffect(() => {
  //   let url = getFullUrl(
  //     `${API_URL.PROPERTIES}?populate=*&sort=projectSequence:ASC&pagination[page]=1`
  //   );

  //   // Append city filter if available
  //   if (defaultFilters?.city) {
  //     url += `&filters[create_city][City_Name][$eqi]=${encodeURIComponent(
  //       defaultFilters.city
  //     )}`;
  //   }

  //   // Append propertyType filter if available
  //   if (defaultFilters?.propertyType) {
  //     url += `&filters[sub_categories][Title][$eqi]=${encodeURIComponent(
  //       defaultFilters.propertyType
  //     )}`;
  //   }

  //   setApiUrl(url);
  // }, [location.pathname, defaultFilters]);

  useEffect(() => {
    if (allCities && selectedCity) {
      const isCityExist = allCities?.find(
        (city) =>
          city?.attributes?.City_Name.toLowerCase() ===
          slugToText(selectedCity.toLowerCase())
      );
      // setInitialMeta("city exist");
      setCityName(selectedCity);

      if (!isCityExist) {
        navigate("/404");
      }
    }
  }, [allCities, selectedCity, navigate, setCityName]);

  useEffect(() => {
    const { pathname, state } = location;
    let initialApiUrl;
    const filters = {};

    if (pathname === URL.SEARCH && state) {
      // console.log("stateee", state);

      const { cityName, selectType, searchBarValue, budget, amenities } =
        state || EMPTY_OBJECT;
      // console.log("amenitiesssssssssssss", amenities);
      initialApiUrl =
        generateSearchedBarUrl(
          cityName || EMPTY_ARRAY,
          selectType || EMPTY_ARRAY,
          searchBarValue || EMPTY_OBJECT,
          budget || EMPTY_OBJECT,
          EMPTY_OBJECT,
          amenities || EMPTY_ARRAY
        ) + "&sort=projectSequence:ASC";
    }
    // console.log("initialApiUrl", initialApiUrl);

    // if (pathname.includes(URL.SEARCH) && propertyName) {
    //   initialApiUrl = generateSearchedBarUrl(EMPTY_ARRAY, [propertyName]);
    // }
    if (selectedCity && selectedCity !== pathname.includes(URL.SEARCH)) {
      initialApiUrl =
        generateSearchedBarUrl([slugToText(selectedCity)]) +
        "&sort=projectSequence:ASC&";
    }

    if (searchParams && category) {
      // console.log("categorycategorycategory", category);

      initialApiUrl = getFullUrl(
        `${
          API_URL.PROPERTIES
        }?filters[$and][0][property_type][Title][$eqi]=${slugToText(
          category
        )}&populate=*`
      );
    }

    if (searchParams && propertyType) {
      initialApiUrl = getFullUrl(
        `/api/projects?filters[sub_categories][Title][$eqi]=${slugToText(
          propertyType
        )}&populate=*&sort=projectSequence:ASC&`
      );
    }

    if (searchParams && buildingType) {
      if (location?.state) {
        initialApiUrl =
          generateSearchedBarUrl(
            location?.state?.cityName || EMPTY_ARRAY,
            location?.state?.selectType || EMPTY_ARRAY,
            location?.state?.searchBarValue || EMPTY_OBJECT,
            location?.state?.budget || EMPTY_OBJECT,
            buildingType || EMPTY_OBJECT
          ) + "&sort=projectSequence:ASC";
      } else {
        initialApiUrl = getFullUrl(
          `/api/projects?filters[category][Title][$eqi]=${slugToText(
            buildingType
          )}&populate=*&sort=projectSequence:ASC`
        );
      }
    }

    if (searchParams && exclusiveProperty) {
      initialApiUrl = getFullUrl(
        `${API_URL?.PROPERTIES}?filters[exclusive]=true&populate=*&sort=projectSequence:ASC`
      );
    }

    if (searchParams && newLaunches) {
      initialApiUrl = getFullUrl(
        `${API_URL?.PROPERTIES}?filters[newLaunches]=true&populate=*&sort=projectSequence:ASC`
      );
    }

    if (defaultFilters?.city) {
      filters["filters[create_city][City_Name][$eqi]"] = encodeURIComponent(
        defaultFilters?.city
      );
    }
    if (defaultFilters?.propertyType) {
      filters["filters[sub_categories][Title][$eqi]"] = encodeURIComponent(
        defaultFilters.propertyType
      );
    }
    if (defaultFilters?.category) {
      filters["filters[category][Title][$eqi]"] = encodeURIComponent(
        defaultFilters.category
      );
    }
    if (defaultFilters?.flatSize) {
      filters["filters[Project_Configuration][$contains]"] = encodeURIComponent(
        `${defaultFilters.flatSize} BHK`
      );
    }
    if (defaultFilters?.min_budget) {
      filters["filters[Min_Price][$gte]"] = encodeURIComponent(
        `${defaultFilters.min_budget}`
      );
    }
    if (defaultFilters?.max_budget) {
      filters["filters[Max_Price][$lte]"] = encodeURIComponent(
        `${defaultFilters.max_budget}`
      );
    }
    if (Object.keys(filters).length > 0) {
      // Convert filters object into a query string
      const queryString = Object.entries(filters)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      initialApiUrl = getFullUrl(
        `${API_URL.PROPERTIES}?${queryString}&populate=*&sort=projectSequence:ASC`
      );
    }

    console.log("Generated API URL:", initialApiUrl);

    setApiUrl(
      initialApiUrl ? `${initialApiUrl}&pagination[page]=${pageNumber}` : ""
    );
  }, [
    pageNumber,
    selectedCity,
    location,
    category,
    searchParams,
    searchTerm,
    propertyType,
    buildingType,
    exclusiveProperty,
    newLaunches,
    defaultFilters?.city,
    defaultFilters?.propertyType,
    defaultFilters?.category,
    defaultFilters?.flatSize,
    defaultFilters?.min_budget,
    defaultFilters?.max_budget,
  ]);

  // useEffect(() => {
  //   if (filterBySortByOrder) {
  //     let sortedParams;

  //     if (filterBySortByOrder === SORTED_BY_OPTIONS.LOW_PRICE) {
  //       sortedParams = { sortBy: "Min_Price", order: "ASC" };
  //     }
  //     if (filterBySortByOrder === SORTED_BY_OPTIONS.HIGH_PRICE) {
  //       sortedParams = { sortBy: "Min_Price", order: "DESC" };
  //     }

  //     if (filterBySortByOrder === SORTED_BY_OPTIONS.A_TO_Z) {
  //       sortedParams = { sortBy: "Project_Name", order: "ASC" };
  //     }

  //     const params = { ...sortedParams, dataLimit: 20 };

  //     const sortedField = getSortQueryByField(params);

  //     setApiUrl((prevUrl) => {
  //       const currentPrevUrl = prevUrl.replace("sort=projectSequence:ASC", "");
  //       return `${currentPrevUrl}${sortedField}`;
  //     });
  //   }
  // }, [filterBySortByOrder]);

  // `${apiUrl}${order ? "" : "&pagination[pageSize]=20"}`
  // const [properties, error, isLoading, pagination] = useApiFetcher(apiUrl);

  // if (error) return <div>{error?.message}</div>;
  // if (!properties || isLoading)
  //   return (
  //     <div>
  //       <Loader />
  //     </div>
  //   );

  // const { pageCount } = pagination;
  // const priorityProperty = properties;
  // filterBySortByOrder
  //   ? properties
  // : getPropertyWithPriority(properties);
  return (
    <>
      <MetaTagHelmet title={title} description={description} link={link} />
      {/* <BreadCrumb /> */}
      <section className="container-fluid cityPageContainer">
        {/* <div className="row border border-4">
          <div className="col-md-9">
            <PropertyPageToolBar
              setOrder={setSortOrder}
              selectedCity={selectedCity}
              properties={propertiesData}
            />
          </div>
        </div> */}
        <div className="row pt-5">
          <div className="col-lg-3">
            <PropertyFilters />
          </div>

          <div className="col-lg-9 cityPageLeftSideContainer">
            {/* Show Loader when data is being fetched */}
            {loading ? (
              <div className="center-item">
                <Loader />
              </div>
            ) : error ? (
              /* Display error message if fetching fails */
              <div className="alert alert-danger text-center">
                {error?.message || "Something went wrong!"}
              </div>
            ) : propertiesData.length > 0 ? (
              /* Show property cards if data is available */
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 propertyCardsContainer">
                {propertiesData.map((property) => (
                  <PropertyCard key={property.id} propertyInfo={property} />
                ))}
              </div>
            ) : (
              /* Show "No Results Found" when data is empty */
              <div className="center-item h-100">
                <Heading
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-item h-100"
                  text="Sorry, no results found!"
                  lineHeight="4rem"
                  color="#ef750f"
                  fontSize={isMobile ? "1.5rem" : "2rem"}
                />
              </div>
            )}
          </div>

          {/* <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 p-0">
            <PropertyForm className={"p-2"} />
          </div> */}
        </div>

        <div className="py-3 center-item">
          <PaginationCustom count={pageCount} state={location?.state} />
        </div>
      </section>
    </>
  );
};

export default CityPages;

// import { useEffect, useState } from "react";
// import {
//   useLocation,
//   useNavigate,
//   useParams,
//   useSearchParams,
// } from "react-router-dom";
// import axios from "axios";

// import BreadCrumb from "../../globalComponents/BreadCrumb";
// import Heading from "../../globalComponents/molecules/Heading";
// import MetaTagHelmet from "../../globalComponents/MetaTagHelmet";
// import PaginationCustom from "../../globalComponents/PaginationCustom/PaginationCustom";
// import PropertyFilters from "./PropertyForm/PropertyFilters";
// import PropertyCard from "./propertyCard";
// import Loader from "../../Loader";

// import useApiFetcher from "../../../hooks/useApiFetcher";
// import useDeviceSize from "../../../hooks/useDeviceSize";
// import useScrollOnTop from "../../../hooks/useScrollOnTop";
// import useMetaTagsInArray from "../../../hooks/useMetaTagsInArray";

// import {
//   getFullUrl,
//   generateSearchedBarUrl,
//   getSortQueryByField,
//   API_URL,
// } from "../../../assets/constants/apiUrls";
// import URL, { URL_PARAMS } from "../../../assets/constants/url";
// import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../assets/constants/index";
// import { SORTED_BY_OPTIONS } from "../../../assets/constants/filters";
// import { slugToText } from "../../../services/slug";

// import "./cityPages.css";

// const CityPages = ({ setCityName }) => {
//   const isMobile = useDeviceSize();
//   const { city: selectedCity } = useParams();
//   const [searchParams] = useSearchParams();
//   const navigate = useNavigate();
//   const location = useLocation();

//   const category = searchParams.get(URL_PARAMS.CATEGORY);
//   const propertyType = searchParams.get(URL_PARAMS.PROPERTY_TYPE);
//   const exclusiveProperty = searchParams.get(URL_PARAMS.EXCLUSIVE_PROPERTY);
//   const newLaunches = searchParams.get(URL_PARAMS.NEW_LAUNCHES);
//   const searchTerm = searchParams.get(URL_PARAMS.SEARCH_TERM);
//   const buildingType = searchParams.get(URL_PARAMS.BUILDING_TYPE);
//   const pageNumber = searchParams.get("page") || 1;

//   const [propertiesData, setPropertiesData] = useState([]);
//   const [pageCount, setPageCount] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");

//   useScrollOnTop(670, 0, location.pathname + location.search);

//   const [filterBySortByOrder, setSortOrder] = useState(null);
//   const [apiUrl, setApiUrl] = useState("");

//   const [allCities] = useApiFetcher(getFullUrl(API_URL.CITY_NAME));
//   const { title, description, link } = useMetaTagsInArray(
//     `/api/create-cities?filters[City_Name][$eqi]=${selectedCity}`
//   );

//   const selectedCityText = slugToText(selectedCity?.toLowerCase());

//   // ✅ Function to construct API URL
//   const getApiUrl = () => {
//     let baseApiUrl = "";

//     if (location.pathname === URL.SEARCH && location.state) {
//       const { cityName, selectType, searchBarValue, budget, amenities } =
//         location.state || EMPTY_OBJECT;

//       baseApiUrl =
//         generateSearchedBarUrl(
//           cityName || EMPTY_ARRAY,
//           selectType || EMPTY_ARRAY,
//           searchBarValue || EMPTY_OBJECT,
//           budget || EMPTY_OBJECT,
//           EMPTY_OBJECT,
//           amenities || EMPTY_ARRAY
//         ) + "&sort=projectSequence:ASC";
//     } else if (selectedCity) {
//       baseApiUrl =
//         generateSearchedBarUrl([selectedCityText]) +
//         "&sort=projectSequence:ASC&";
//     }

//     if (category) {
//       baseApiUrl = getFullUrl(
//         `${API_URL.PROPERTIES}?filters[property_type][Title][$eqi]=${slugToText(
//           category
//         )}&populate=*`
//       );
//     } else if (propertyType) {
//       baseApiUrl = getFullUrl(
//         `/api/projects?filters[sub_categories][Title][$eqi]=${slugToText(
//           propertyType
//         )}&populate=*&sort=projectSequence:ASC&`
//       );
//     } else if (buildingType) {
//       baseApiUrl = getFullUrl(
//         `/api/projects?filters[category][Title][$eqi]=${slugToText(
//           buildingType
//         )}&populate=*&sort=projectSequence:ASC`
//       );
//     } else if (exclusiveProperty) {
//       baseApiUrl = getFullUrl(
//         `${API_URL.PROPERTIES}?filters[exclusive]=true&populate=*&sort=projectSequence:ASC`
//       );
//     } else if (newLaunches) {
//       baseApiUrl = getFullUrl(
//         `${API_URL.PROPERTIES}?filters[newLaunches]=true&populate=*&sort=projectSequence:ASC`
//       );
//     } else if (searchTerm) {
//       baseApiUrl = getFullUrl(
//         `${API_URL.PROPERTIES}?filters[Slug_Url]=${searchTerm}&populate=*&sort=projectSequence:ASC`
//       );
//     }

//     return `${baseApiUrl}&pagination[page]=${pageNumber}`;
//   };

//   useEffect(() => {
//     setApiUrl(getApiUrl());
//   }, [
//     pageNumber,
//     selectedCity,
//     location,
//     category,
//     searchParams,
//     searchTerm,
//     propertyType,
//     buildingType,
//     exclusiveProperty,
//     newLaunches,
//   ]);

//   useEffect(() => {
//     if (apiUrl) {
//       setLoading(true);
//       setPropertiesData([]); // Clears old data

//       axios
//         .get(apiUrl)
//         .then((res) => {
//           if (res.status === 200) {
//             setPropertiesData(res.data.data);
//             setPageCount(res.data.meta.pagination.pageCount);
//             setError("");
//           }
//         })
//         .catch((err) => {
//           console.error("Error fetching properties:", err);
//           setError(err.message);
//         })
//         .finally(() => setLoading(false));
//     }
//   }, [apiUrl]);

//   return (
//     <>
//       <MetaTagHelmet title={title} description={description} link={link} />
//       <section className="container-fluid cityPageContainer">
//         <div className="row pt-5">
//           <div className="col-lg-3">
//             <PropertyFilters />
//           </div>
//           <div className="col-lg-9 cityPageLeftSideContainer">
//             {error && <div>{error}</div>}
//             {loading && <Loader />}
//             {propertiesData.length > 0 ? (
//               <div className="propertyCardsContainer">
//                 {propertiesData.map((property) => (
//                   <PropertyCard key={property.id} propertyInfo={property} />
//                 ))}
//               </div>
//             ) : (
//               <Heading text="Sorry, no results found!" color="#ef750f" />
//             )}
//           </div>
//         </div>
//         <div className="py-3 center-item">
//           <PaginationCustom count={pageCount} state={location.state} />
//         </div>
//       </section>
//     </>
//   );
// };

// export default CityPages;
