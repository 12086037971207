import React, { useCallback, useEffect, useState } from "react";
import useApiFetcher from "../../../hooks/useApiFetcher";

import AwardCard from "./AwardCard";
import Heading from "../../globalComponents/molecules/Heading";

import { API_URL, getFullUrl } from "../../../assets/constants/apiUrls";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../assets/constants";
import { AWARD_TAB, AWARD_TABS } from "./constant";
import "./AwardCard/awardCard.css";

const Awards = () => {
  const [employeeAwards, setEmployeeAwards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const companyUrl = getFullUrl(`${API_URL.AWARDS}${API_URL.POPULATE}`);
  const employeeUrl = getFullUrl(
    `${API_URL.EMPLOYEE_AWARDS}${API_URL.POPULATE}`
  );

  const [activeTabId, setActiveTabId] = useState(AWARD_TAB.COMPANY);
  const [initialYear, setInitialYear] = useState("2023");

  const [awards, companyError, companyIsLoading] = useApiFetcher(companyUrl);

  console.log("activeTabId", activeTabId);

  useEffect(() => {
    const fetchAllAwards = async () => {
      try {
        setLoading(true);
        let allAwards = [];
        let page = 1;
        let hasMoreData = true;

        while (hasMoreData) {
          const response = await fetch(
            `https://bold-approval-c005df0fb8.strapiapp.com/api/employee-awards?populate=*&pagination[page]=${page}`
          );
          const data = await response.json();

          if (data?.data?.length) {
            allAwards = [...allAwards, ...data.data];
            page++;
          } else {
            hasMoreData = false;
          }
        }

        setEmployeeAwards(allAwards);
      } catch (err) {
        setError("Failed to load employee awards");
      } finally {
        setLoading(false);
      }
    };

    fetchAllAwards();
  }, [employeeUrl]);

  const handleActiveTabBtn = useCallback((tabValue) => {
    setActiveTabId(tabValue);
  }, []);

  if (companyError) {
    return <div>Error loading company awards: {companyError.message}</div>;
  }
  if (error) {
    return <div>Error loading employee awards: {error}</div>;
  }
  if (companyIsLoading || loading) {
    return <div>Loading awards...</div>;
  }

  const isCompanyTabActive = activeTabId === AWARD_TAB.COMPANY;

  const awardsInfo = extractAwardsDetails(
    isCompanyTabActive ? awards : employeeAwards
  );
  const mappedAwards = mappingAwardsByYear(awardsInfo);
  const sortedAwardsYears = Object.keys(mappedAwards).sort((a, b) => b - a);
  const displayedAwardsImagesByYear = mappedAwards[initialYear];

  return (
    <div className="container-fluid p-2 ">
      <div className="container mx-auto ">
        <div className="awardsTabsContainer p-4">
          {AWARD_TABS?.map((tabValue, idx) => (
            <TabButton
              key={idx}
              tabValue={tabValue}
              isTabActive={tabValue === activeTabId}
              onClick={handleActiveTabBtn}
            />
          ))}
        </div>

        <div className="p-3 pb-3">
          <Heading
            text={activeTabId}
            color={"black"}
            className="fw-bold text-center"
          />
        </div>

        <div className="col-md-12 py-2 d-flex flex-wrap gap-2 justify-content-center mb-4">
          {sortedAwardsYears?.map((year, idx) => (
            <button
              key={idx}
              onClick={() => setInitialYear(year)}
              className="btn mx-1 rounded-pill text-white"
              style={{
                backgroundColor: year === initialYear ? "#ef750f" : "#6c757d",
                borderColor: year === initialYear ? "#ef750f" : "#6c757d",
              }}
            >
              {year}
            </button>
          ))}
        </div>

        <div
          className={`row d-flex justify-content-center text-center  mx-auto ${
            activeTabId === AWARD_TAB.COMPANY ? "col-md-12" : "col-md-10"
          }`}
        >
          {displayedAwardsImagesByYear?.map((awardsInfo, idx) => (
            <AwardCard
              key={idx}
              awardData={awardsInfo}
              activeTabId={activeTabId}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Awards;

export const TabButton = React.memo(({ tabValue, isTabActive, onClick }) => {
  const getTabButtonClass = () => (isTabActive ? "tabBtn_active" : "");
  return (
    <div className="col-md-4">
      <div
        className={`tabBtn ${getTabButtonClass()}`}
        onClick={() => onClick(tabValue)}
      >
        <span>{tabValue}</span>
      </div>
    </div>
  );
});

const extractAwardsDetails = (awards) =>
  awards?.flatMap((award) => {
    const { Award_Title, Award_Date, Description, Award_Image } =
      award?.attributes || EMPTY_OBJECT;

    return {
      img: Award_Image?.data?.attributes?.url,
      title: Award_Title,
      date: Award_Date,
      description: Description,
    };
  }) || EMPTY_ARRAY;

const mappingAwardsByYear = (data) => {
  return data?.reduce((acc, nextAwardDetails) => {
    const { img, title, date, description } = nextAwardDetails;
    if (!date) return acc;

    const newYear = new Date(date).getFullYear();
    if (!acc[newYear]) {
      acc[newYear] = [];
    }

    acc[newYear].push({
      title,
      description,
      img,
    });

    return acc;
  }, {});
};

// const extractAwardImageUrl = (awards) =>
//   awards
//     ?.map((award) => award?.attributes?.Award_Image?.data)
//     .map((image) => `${image.attributes?.url}`) || EMPTY_ARRAY;

// export const TabButton = ({ tabValue }) => {
//   const [activeTabId, setActiveTabId] = useState(null);
//   const [isTabActive, setIsTabActive] = useState("");

//   const handleTabBtnChange = (tabValue) => {
//     const tabBtnStyle = (tabValue) => {
//       return tabValue === activeTabId ? "tabBtn_active" : "";
//     };
//     console.log("clicked");
//     setActiveTabId(tabValue);
//     setIsTabActive(tabBtnStyle(tabValue));
//   };

//   console.log("======", isTabActive, "====", activeTabId);
//   return (
//     <div className="col-md-4">
//       <div
//         className={`tabBtn ${isTabActive}`}
//         onClick={() => handleTabBtnChange(tabValue)}
//       >
//         <span>{tabValue}</span>
//       </div>
//     </div>
//   );
// };
