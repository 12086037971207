import "react-phone-input-2/lib/style.css";
import "./QuerySectionTemplate.css";
import QueryForm2 from "../../globalComponents/queryForm2/QueryForm2";
// import Emoji from "../../../assets/emoji.gif";

const QuerySectionTemplate = () => {
  // const [phone, setPhone] = useState("");
  // const [name, setName] = useState("");
  // const [submitted, setSubmitted] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setSubmitted(true);
  // };

  return (
    <div className="m-0 my-5  QuerySectionTemplate p-5">
      <div className="container hover-section">
        <div className="row d-flex justify-content-center align-items-center pt-4">
          <div className="col-md-6 col-12">
            <h2 className=" responsive-heading">Discover your Dream Space</h2>
          </div>
          <div className="col-md-5 mt-4 mt-4 ms-auto ">
            <QueryForm2 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuerySectionTemplate;
