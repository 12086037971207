import { FaRegShareFromSquare } from "react-icons/fa6";
import { IoBedOutline } from "react-icons/io5";
import { FaRupeeSign } from "react-icons/fa";
import { RiAttachment2 } from "react-icons/ri";
import { useState } from "react";

import CustomLink from "../../../CustomLink";
import Heading from "../../../globalComponents/molecules/Heading";
import CallBackPopUpForm from "../../../globalComponents/CallBackPopUpForm";
import WishListBtn from "../../../globalComponents/WishListBtn";
import ConvertStringToHtml from "./../../../globalComponents/ConvertStringToHtml";

import { sendDirectWhatsApp } from "../../../globalComponents/CallBackPopUpForm/form.services";
import { slugFormat } from "../../../../services/slug";
import { generatePropertyDetailsURL } from "../../../../helpers/getRedirectUrl";
import { shareContent } from "../../../../services/shareContentSlug";
import { propertyParams } from "../../../../helpers/params";
import "react-toastify/dist/ReactToastify.css";
import "./propertyCard.css";

export const PropertyCard = ({ propertyInfo }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleShareClick = async (URL) => {
    const websiteLink = slugFormat(URL);
    const shareMessage = `Hey 👋,
    \nCheckout this property`;
    const title = "Wealth Clinic";

    await shareContent(title, shareMessage, websiteLink);
  };

  const {
    propertyId,
    Slug_Url,
    Project_Name,
    Project_Configuration,
    Address,
    description,
    // Image,
    altTag,
    imgTitle,
    minPrice,
    maxPrice,
    mobileImg,
    cityName,
  } = propertyParams(propertyInfo);

  const img = mobileImg;
  const configurations =
    Project_Configuration && Project_Configuration.split(" | ");

  const handleCallBackPopUpBtn = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const handleClosForm = () => setIsOpen(false);

  const handleWhatsAppBtn = () =>
    sendDirectWhatsApp({ projectName: Project_Name });

  return (
    <div className="property-card ">
      <CustomLink
        to={generatePropertyDetailsURL(cityName, Slug_Url)}
        state={{ propertyId }}
      >
        <div className="row ">
          <div className="col-lg-5 col-md-5 col-sm-5">
            <div className="city-image-card h-100 p-0 m-0 vertical-center ">
              <img
                className="card-city-img"
                src={img}
                alt={altTag}
                title={imgTitle}
              />
            </div>
          </div>

          <div className="col-lg-7 col-md-7  col-sm-7">
            <div className="card-main-head">
              <span>
                {Project_Name} <RiAttachment2 />
              </span>
              <div className="d-flex">
                <div
                  className="shareIcon mb-2 px-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleShareClick(
                      generatePropertyDetailsURL(cityName, Slug_Url)
                    );
                  }}
                >
                  <FaRegShareFromSquare />
                </div>

                <WishListBtn cardData={propertyParams(propertyInfo)} />
              </div>
            </div>
            <div className="card-city-sec-1">
              <IoBedOutline />

              {Address}
            </div>
            <span className="card-city-price-1">
              <FaRupeeSign />
              {minPrice} - {maxPrice}
            </span>
            <hr></hr>
            <div>
              {configurations?.map((item, idx) => (
                <TagBtn key={idx} text={item} />
              ))}
            </div>
            <hr></hr>
            <div className="city-details-desc">
              <Heading
                text={<ConvertStringToHtml htmlData={description} />}
                color={"444"}
                fontSize=".75rem"
                className="overflow-hidden"
              />
            </div>

            <div className="row  properties-card-btn-container">
              <button
                className="col-lg-2 col-md-2 col-sm-2 col-xs-1 col-2 whatsapp-btn"
                onClick={handleWhatsAppBtn}
              >
                <i className="fa-brands fa-whatsapp"></i>
              </button>
              <button className="col-lg-6 col-md-6 col-sm-6 col-xs-7 mx-3  col-6 properties-detail">
                See Full details
              </button>
              <button
                className="col-lg-2 col-md-2 col-sm-2 col-xs-1 col-2 phone-btn"
                onClick={handleCallBackPopUpBtn}
              >
                <i className="fa-solid fa-phone-flip"></i>
              </button>
            </div>
          </div>
        </div>
      </CustomLink>
      {isOpen && (
        <CallBackPopUpForm
          isOpen={isOpen}
          onClose={handleClosForm}
          formName={`${Project_Name} card form`}
        />
      )}
    </div>
  );
};

export default PropertyCard;

function TagBtn({ text }) {
  return (
    <button className="btn btn-sm btn-shadow-card btn-tab-font">{text}</button>
  );
}
