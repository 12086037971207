import { useDispatch } from "react-redux";
import { removeItemFromWishlist } from "../../../Redux/slice/wishListSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
// import Heading from "../../../globalComponents/molecules/Heading";
import Heading from "../molecules/Heading";
import "./wishlist.css";
const deleteBtnStyle = {
  color: "#555",
  "&:hover": {
    color: "#ef750f",
  },
};
const WishListCard = ({ data }) => {
  const { id, img, address, projectName, price } = data;

  const dispatch = useDispatch();

  return (
    <div className="col-md-12 my-1 d-flex justify-content-center">
      <div className="row side-bar-main m-0 p-0">
        <div className="col-md-5 col-md-5  side-bar-container">
          <img src={img} alt="img" className="img-fluid" />
        </div>
        <div className="col-lg-7 col-md-7  pt-2 sideInfoContainer">
          <div className="d-flex">
            <Heading
              text={projectName}
              fontSize="0.75rem"
              color="#E5750F"
              lineHeight="1rem"
              showLine="1"
              className="col-md-10"
            />

            <DeleteForeverIcon
              onClick={() => dispatch(removeItemFromWishlist(id))}
              sx={deleteBtnStyle}
            />
          </div>

          <Heading text={`₹ ${price}`} fontSize="0.75rem" color="#888" />
          <Heading text={address} fontSize="0.75rem" color="#000" />
        </div>
      </div>
    </div>
  );
};

export default WishListCard;
