import { useState } from "react";

// import CustomLink from "../CustomLink";
// import { FindPropertyDropDownBtn } from "../globalComponents/headerBtn/HeaderBtn";

import { getPropertyType } from "../globalComponents/DropDown/filter.helper";
import { SORTED_BY_PROPERTY_TYPE } from "../../assets/constants/filters";
import RESIDENTIAL from "../../assets/FindPropertyImages/Residentials.jpg";
import COMMERCIAL from "../../assets/FindPropertyImages/Residentials2.jpg";
import FIND_PROPERTY from "../../assets/FindPropertyImages/Residentials4.jpg";
import plots from "../../assets/customDropdowns/plots.png";
import flats from "../../assets/customDropdowns/flats-appartments.png";
import secondHomes from "../../assets/customDropdowns/second-homes.png";
import villas from "../../assets/customDropdowns/villas.png";
import officeSpace from "../../assets/customDropdowns/office-space.png";
import retailShop from "../../assets/customDropdowns/retail-shop.png";
import foodCourt from "../../assets/customDropdowns/food-court.png";
import studio from "../../assets/customDropdowns/studio.png";
import Business from "../../assets/customDropdowns/business-suits.png";
// import {
//   BiAbacus,
//   BiAccessibility,
//   BiAddToQueue,
//   BiAdjust,
//   BiAlarm,
//   BiAlarmAdd,
//   BiAlarmExclamation,
//   BiAlarmOff,
//   BiAlarmSnooze,
//   BiAlbum,
// } from "react-icons/bi";

import styles from "./dropDown.module.css";
import { useNavigate } from "react-router-dom";

// const BUTTON_DATA = [
//   SORTED_BY_PROPERTY_TYPE.RESIDENTIAL,
//   SORTED_BY_PROPERTY_TYPE.COMMERCIAL,
// ];

const RESIDENTIAL_NESTED_ICONS = [flats, villas, secondHomes, plots];

const COMMERCIAL_NESTED_ICONS = [
  officeSpace,
  retailShop,
  foodCourt,
  studio,
  Business,
];

const FIND_PROPERTY_DATA = [
  {
    name: SORTED_BY_PROPERTY_TYPE.RESIDENTIAL,
    Imagesrc: RESIDENTIAL,
    nestedData: getPropertyType(SORTED_BY_PROPERTY_TYPE.RESIDENTIAL).map(
      (d, idx) => ({
        name: d,
        icon: RESIDENTIAL_NESTED_ICONS[idx],
        url: `/search?property-type=${d?.split(" ").join("-").toLowerCase()}`,
      })
    ),
  },
  {
    name: SORTED_BY_PROPERTY_TYPE.COMMERCIAL,
    Imagesrc: COMMERCIAL,
    nestedData: getPropertyType(SORTED_BY_PROPERTY_TYPE.COMMERCIAL).map(
      (d, idx) => ({
        name: d,
        icon: COMMERCIAL_NESTED_ICONS[idx],
        url: `/search?property-type=${d?.split(" ").join("-").toLowerCase()}`,
      })
    ),
  },
  {
    name: "Second Home",
    Imagesrc: FIND_PROPERTY,
    nestedData: [],
  },
];
const FindPropertyDropDown = ({
  children,
  listStyle,
  styleBtn,
  // getValue: setValue,
  btnText,
  childrenBtn,
}) => {
  // console.log("FIND_PROPERTY_DATA", FIND_PROPERTY_DATA);
  // console.log("====================================");
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isNestedDropDownTypeOpen, setIsNestedDropDownTypeOpen] =
    useState(true);

  // const [radioBtnValue, setRadioBtnValue] = useState(null);
  // const [nested, setNested] = useState(false);
  const navigate = useNavigate();
  const handleCloseDropDown = () => {
    // setRadioBtnValue(null);
    setIsDropDownOpen(false);
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // console.log(">>>>>>>>>", { BUTTON_DATA, btnText, radioBtnValue });
  // console.log("nested", nested);
  // console.log("FIND_PROPERTY_DATA", FIND_PROPERTY_DATA);

  return (
    <div
      className={`${styles.dropDown}`}
      onMouseEnter={() => {
        setIsDropDownOpen(true);
        // setNested(false);
      }}
      onMouseLeave={handleCloseDropDown}
    >
      <div div className={styles.dropDownPad}>
        <div
          className={`${styles.dropDownBtn}`}
          style={styleBtn}
          onClick={() => {
            setIsNestedDropDownTypeOpen(true);
            setIsDropDownOpen(!isDropDownOpen);
          }}
        >
          <div>{btnText || "Type"}</div>
          <div className="dropDownArrow">
            <i
              className={`fa fa-chevron-right icon ms-2 ${
                isDropDownOpen && "open"
              }`}
            ></i>
          </div>
        </div>
        {isDropDownOpen && (
          <>
            <div
              className="position-absolute top-100 pt-4 z-10 w-100"
              // style={{ left: "-200px" }}
            >
              <div
                className={`${styles.dropDownList} row p-0 p-lg-4 pb-0 `}
                // style={{ width: "50vw" }}
              >
                {FIND_PROPERTY_DATA?.map((property, idx) => (
                  <>
                    <div className="col-md-4 col-4 position-relative d-none d-lg-block">
                      <div
                        className="rounded-2 d-none d-md-block position-relative"
                        style={{
                          width: "100%",
                          height: "300px",
                          backgroundImage: `url(${property.Imagesrc})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        onMouseEnter={() => setHoveredIndex(idx)}
                        onMouseLeave={() => setHoveredIndex(null)}
                      >
                        {/* Dark Overlay */}
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity
                          }}
                        ></div>

                        {/* Content with Hover */}
                        <div
                          className="position-md-absolute position-relative w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white"
                          style={{ zIndex: 2 }}
                        >
                          {hoveredIndex === idx &&
                          property?.nestedData?.length > 0 ? (
                            <div className="d-flex flex-wrap justify-content-around">
                              {property?.nestedData?.map((item, itemIndex) => (
                                <div
                                  key={itemIndex}
                                  className="d-flex flex-column justify-content-center text-white gap-2 align-items-center"
                                  onClick={() => {
                                    navigate(item?.url);
                                    setIsDropDownOpen(false);
                                  }}
                                >
                                  <img
                                    src={item.icon}
                                    alt={item.icon}
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                </div>
                              ))}
                            </div>
                          ) : (
                            property?.nestedData?.length === 0 && (
                              <div>Invest In Future</div>
                            )
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "19px",
                          fontWeight: "600",
                          textAlign: "center",
                          padding: "3px 0",
                          margin: "3px 0",
                        }}
                      >
                        {property?.name}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div
              className=" d-lg-none"
              // style={{ left: "-200px" }}
            >
              <div
                className={` row p-0 p-lg-4 pb-0 `}
                // style={{ width: "50vw" }}
              >
                {FIND_PROPERTY_DATA?.map((property, idx) => (
                  <>
                    {/* ✅ Mobile View (Without Hover & Background) */}
                    <div className="col-4  py-3 d-lg-none">
                      <div className="d-flex flex-column ">
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            textAlign: "center",
                            padding: "3px 0",
                            margin: "3px 0",
                          }}
                        >
                          {property?.name}
                        </div>
                        {property?.nestedData?.map((item, itemIndex) => (
                          <div
                            key={itemIndex}
                            className="d-flex flex-column justify-content-center align-items-center text-white  pb-2"
                            style={{
                              borderRadius: "8px",
                            }}
                            onClick={() => {
                              navigate(item?.url);
                              setIsDropDownOpen(false);
                            }}
                          >
                            {/* <img
                         src={item.icon}
                         alt={item.icon}
                         style={{ width: "30px", height: "30px" }}
                       /> */}
                            <span
                              style={{
                                fontSize: "9px",
                                fontWeight: "500",
                                // paddingBottom: "5px",
                              }}
                            >
                              {item.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FindPropertyDropDown;
