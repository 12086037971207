// import ReadMore from "../../../globalComponents/molecules/ReadMore";
import Heading from "../../../globalComponents/molecules/Heading";

import useApiFetcher from "../../../../hooks/useApiFetcher";
// import useDeviceSize from "../../../../hooks/useDeviceSize";
import { getFullUrl, API_URL } from "../../../../assets/constants/apiUrls";
// import CircleBgPic from "../../../../assets/cricle_bg.png";
// import ReadMore from "../../../globalComponents/molecules/ReadMore";
import { EMPTY_OBJECT } from "../../../../assets/constants";
import "./ourTeam.css";

const OurTeamTitle = () => {
  const url = getFullUrl(`${API_URL.ABOUT_US}${API_URL.POPULATE}`);

  const [ourTeam, error, isLoading] = useApiFetcher(url);
  // const isMobile = useDeviceSize();

  if (error) return <div>{error.message}</div>;
  if (isLoading) return null;

  const {
    Owner_Name = "",
    Owner_Designation,
    Full_Description,
    Owner_Name_2 = "",
    Owner_Designation_2,
    Full_Description_2,
    Owner_Image,
    Owner_Image_2,
  } = ourTeam?.attributes || EMPTY_OBJECT;

  const img =
    Owner_Image?.data?.attributes?.url ||
    "https://wealth-clinic.com/public/frontend/images/amit_raheja.jpg";
  const img2 =
    Owner_Image_2?.data?.attributes?.url ||
    "https://wealth-clinic.com/public/frontend/images/sumit_raheja.jpg";

  return (
    <>
      <div className="row top-bottom-team border rounded-5 ">
        <div className="col-md-6 p-0  ">
          <img
            className="ourTeam1 rounded-start-5 "
            alt="ourTeam1 image"
            src={img}
          />
        </div>
        <div className="col-md-6 d-flex align-items-center rounded-end-5 ourteammembers ">
          <div className="bgDirector">
            <Heading
              text={Owner_Name}
              color="#000"
              fontWeight="700"
              className="OurTeamtitle"
            />

            <p className="desgination">
              <Heading
                text={Owner_Designation}
                fontSize="20px"
                fontWeight="350"
                color="#000"
                lineHeight="1.5"
                className="bor-down"
              />
            </p>
            <p className="fullDescription">{Full_Description}</p>
          </div>
        </div>
      </div>

      <div className="row top-bottom-team border rounded-5">
        <div className="col-md-6 d-flex align-items-center rounded-start-5 ourteammembers ">
          <div className="bgDirector ">
            {/* <img className="ourTeambgImg1" alt="" src={CircleBgPic} /> */}
            <Heading
              text={Owner_Name_2}
              color="#000"
              fontWeight="700"
              className="OurTeamtitle"
            />

            <p className="desgination">
              <Heading
                text={Owner_Designation_2}
                fontSize="21px"
                fontWeight="350"
                color="#000"
                lineHeight="1.5"
                className="bor-down"
              />
            </p>
            <p className="fullDescription">{Full_Description_2}</p>
          </div>
        </div>
        <div className="col-md-6 p-0 ">
          <img
            className="ourTeam1 rounded-end-5"
            alt="our team image 2"
            src={img2}
          />
        </div>
      </div>
    </>
  );
};
export default OurTeamTitle;
